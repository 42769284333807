import React, {
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useEffect,
} from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
} from '@mui/material';
import { useDisclosure } from '@mantine/hooks';
import i18n from 'i18n';

import { useCustomMediaQuery } from 'hooks';
import DefaultModelHeader from './ModelHeaders/DefaultModelHeader';
import ModelFooter from './ModelFooter';
import ModelHeaderWithSubmit from './ModelHeaders/ModelHeaderWithSubmit';

export type CustomDialogProps = {
  isOpen?: boolean;
  title: string | JSX.Element;
  cancelButtonText?: string;
  confirmButton?: ReactElement;
  secondaryActionButton?: ReactElement | null;
  maxWidth: DialogProps['maxWidth'];
  noFooter?: boolean;
  noHeader?: boolean;
  dialogProps?: Omit<DialogProps, 'open'>;
  dialogTitleProps?: DialogTitleProps;
  dialogContentProps?: DialogContentProps;
  headerStyle?: 'default' | 'with-submit';
  renderHeader?: (close: () => void) => JSX.Element;
  triggerButton?: JSX.Element;
  closeDialog?: () => void;
  actionBeforeClose?: (close: () => void) => void;
};

const CustomDialog = ({
  isOpen,
  children,
  title,
  cancelButtonText = i18n.t('common.cancel', 'Cancel', { ns: 'common' }),
  confirmButton,
  secondaryActionButton,
  maxWidth,
  noFooter,
  noHeader,
  dialogProps,
  dialogTitleProps,
  dialogContentProps,
  headerStyle = 'default',
  triggerButton,
  closeDialog,
  actionBeforeClose,
}: PropsWithChildren<CustomDialogProps>) => {
  const [isDialogOpen, { open, close }] = useDisclosure(Boolean(isOpen));

  const fullScreen = useCustomMediaQuery('sm');

  useEffect(() => {
    if (isOpen) open();
    else {
      close();
    }
  }, [isOpen]);

  const handleCloseDialog = () => {
    if (actionBeforeClose) {
      actionBeforeClose(() => {
        close();
        if (closeDialog) closeDialog();
      });
      return;
    }

    close();
    if (closeDialog) closeDialog();
  };

  const dialogtriggerBtn =
    triggerButton &&
    cloneElement(triggerButton, {
      onClick: open,
    });

  return (
    <>
      {dialogtriggerBtn}
      <Dialog
        fullScreen={fullScreen}
        open={isDialogOpen}
        maxWidth={maxWidth}
        onClose={handleCloseDialog}
        fullWidth
        {...dialogProps}
      >
        {!noHeader && (
          <DialogTitle {...dialogTitleProps}>
            {headerStyle === 'default' && (
              <DefaultModelHeader
                title={title}
                handleClose={handleCloseDialog}
              />
            )}
            {headerStyle === 'with-submit' && (
              <ModelHeaderWithSubmit
                title={title}
                handleClose={handleCloseDialog}
                cancelButtonText={cancelButtonText}
                confirmButton={confirmButton}
                secondaryActionButton={secondaryActionButton}
              />
            )}
          </DialogTitle>
        )}
        <DialogContent {...dialogContentProps}>{children}</DialogContent>
        {!noFooter && (
          <DialogActions>
            <ModelFooter
              confirmButton={confirmButton}
              cancelButtonText={cancelButtonText}
              handleClickCancel={handleCloseDialog}
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default CustomDialog;
