import React, { useMemo } from 'react';

import { ThemeProvider, createTheme } from '@mui/material';
import { type ThemeProviderProps } from '@mui/material/styles/ThemeProvider';

import useMainTheme from './useMainTheme';

interface IProps {
  children: React.ReactNode;
}

const MainThemeProvider = ({ children }: IProps) => {
  const mainTheme = useMainTheme();

  return <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>;
};

export const ExtendMainThemeProvider = ({
  theme,
  children,
}: ThemeProviderProps & { children: React.ReactNode }) => {
  const mainTheme = useMainTheme();

  const extendedTheme = useMemo(
    () => createTheme(mainTheme, theme),
    [mainTheme],
  );

  return <ThemeProvider theme={extendedTheme}>{children}</ThemeProvider>;
};

export default MainThemeProvider;
