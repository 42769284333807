import React from 'react';

import { Stack, type StackProps } from '@mui/material';
import {
  Controller,
  type ControllerProps,
  type FieldValues,
} from 'react-hook-form';

import ErrorMessage from './ErrorMessage';

const ControllerWithError = <T extends FieldValues>({
  render,
  stackProps,
  ...otherProps
}: ControllerProps<T> & { stackProps?: StackProps }) => (
  <Controller
    render={(args) => (
      <Stack {...stackProps}>
        {render(args)}
        <ErrorMessage name={otherProps.name} errors={args.formState.errors} />
      </Stack>
    )}
    {...otherProps}
  />
);

export default ControllerWithError;
