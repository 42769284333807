import React from 'react';

import { Route, Routes } from 'react-router';

import NotFoundPage from 'components/NotFoundPage';

const CustomRoutes = ({ children }: { children: React.ReactNode }) => (
  <Routes>
    {children}
    <Route path="/*" element={<NotFoundPage />} />
  </Routes>
);

export default CustomRoutes;
