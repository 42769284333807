import { useSnackbar } from 'notistack';

import type { User } from 'services/api/general';
import { UseHandleFormDataArgs } from 'types';
import {
  useUsersCreateMutation,
  useUsersUpdateMutation,
} from 'services/api/general';
import { useTranslation } from 'react-i18next';

const useHandleUserFormData = ({ action }: UseHandleFormDataArgs) => {
  const { t } = useTranslation('common');
  const [createUser] = useUsersCreateMutation();
  const [updateUser] = useUsersUpdateMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateUser = (data: User) =>
    new Promise<User>((resolve, reject) => {
      createUser({ user: data })
        .unwrap()
        .then((resp) => {
          enqueueSnackbar(
            `${t('settings.user', 'User')} ${data.first_name} ${t(
              'settings.createSuccess',
              'created successfully',
            )}.`,
            {
              variant: 'success',
            },
          );
          resolve(resp);
        })
        .catch((err) => {
          enqueueSnackbar(
            `${t('settings.user', 'User')} ${data.first_name} ${t(
              'settings.createFail',
              'create failed',
            )}: ${JSON.stringify(err)}`,
            {
              variant: 'error',
            },
          );
          reject(err);
        });
    });

  const handleUpdateUser = ({ password, ...data }: User) =>
    new Promise<User>((resolve, reject) => {
      updateUser({ id: data.id, user: { password: password || '', ...data } })
        .unwrap()
        .then((resp) => {
          enqueueSnackbar(
            `${t('settings.user', 'User')} ${data.first_name} ${t(
              'settings.updateSuccess',
              'updated successfully',
            )}.`,
            {
              variant: 'success',
            },
          );
          resolve(resp);
        })
        .catch((err) => {
          enqueueSnackbar(
            `${t('settings.user', 'User')} ${data.first_name} ${t(
              'settings.updateFail',
              'update failed',
            )}.`,
            {
              variant: 'error',
            },
          );
          reject(err);
        });
    });

  return (data: User) => {
    if (action === 'create') {
      return handleCreateUser(data);
    }

    return handleUpdateUser(data);
  };
};

export default useHandleUserFormData;
