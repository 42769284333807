import React, { PropsWithoutRef } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ResourceFormProps } from 'types';
import type { User } from 'services/api/general';
import { FormFieldDescription } from 'components/common/Forms';
import useIsInternalUsersPage from 'hooks/useIsInternalUsersPage';
import UserSitesSelectDropdown from './UserSitesSelectDropdown';
import UserGroupSelectDropdown from './UserGroupSelectDropdown';
import UserCompanyDropdown from './UserCompanyDropdown';
import UserResellerDropdown from './UserResellerDropdown';

const UserFormPermsConfigSection = ({
  control,
  watch,
}: PropsWithoutRef<ResourceFormProps<User>>) => {
  const { t } = useTranslation('common');
  const isInternalUsersPage = useIsInternalUsersPage();

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'grid' }}>
        <FormFieldDescription
          title={t('settings.userGroup', 'User Group')}
          subtitle={t(
            'settings.userGroupUserBelongsTo',
            'The user group the user belongs to',
          )}
          required
        />
        <UserGroupSelectDropdown control={control} />
      </Box>

      {isInternalUsersPage ? (
        <UserResellerDropdown control={control} />
      ) : (
        <>
          <UserCompanyDropdown control={control} />
          <Box sx={{ display: 'grid' }}>
            <FormFieldDescription
              title={t('settings.userSites', 'User Sites')}
              subtitle={t(
                'settings.siteUserBelongsTo',
                'The site that the user belongs to',
              )}
              required
            />
            <UserSitesSelectDropdown control={control} watch={watch} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserFormPermsConfigSection;
