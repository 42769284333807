import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export type GroupsFilterState = {
  filteredGroupIds: string[];
};

const initialState: GroupsFilterState = {
  filteredGroupIds: [],
};

export const groupsFilterSlice = createSlice({
  name: 'groupsFilter',
  initialState,
  reducers: {
    filterGroup: (state, action: PayloadAction<string[]>) => {
      state.filteredGroupIds = action.payload;
    },
  },
});

export const { filterGroup } = groupsFilterSlice.actions;

export default groupsFilterSlice.reducer;
export const getSelectedGroupIds = (state: RootState) =>
  state.groupsFilter.filteredGroupIds;
