import React from 'react';

import { List, Paper } from '@mui/material';

import useSearchData from 'hooks/useSearchData';
import type {
  Columns,
  Column,
  SelectedItems,
  HandleSelectColumn,
} from '../../types';
import ListItem from '../ListItem';
import useToggleColumn from './useToggleColumn';
import { isSomeSelected, isEverythingSelected } from '../../utils';
import ColumnHeaderWithSearch from './ColumnHeaderWithSearch';
import ColumnSelectAll from './ColumnSelectAll';

interface IProps {
  columns: Columns;
  setSelectedColumn: React.Dispatch<React.SetStateAction<Column | undefined>>;
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
  selectedColumn?: Column;
  handleSelectColumn?: HandleSelectColumn;
  threeColumn: boolean;
  columnHeader: string;
  disableColumnSelection?: boolean;
  selectAllColumn?: boolean;
}

const ColumnList = ({
  columns,
  setSelectedColumn,
  selectedItems,
  setSelectedItems,
  selectedColumn,
  handleSelectColumn,
  threeColumn,
  columnHeader,
  disableColumnSelection,
  selectAllColumn,
}: IProps) => {
  const [columnsToDisplay, , searchColVal, setSearchColVal] = useSearchData({
    data: columns,
    lookupField: 'name',
  });

  const handleToggleColumn = useToggleColumn({
    selectedItems,
    setSelectedItems,
  });

  return (
    <Paper sx={{ height: '100%' }}>
      <ColumnHeaderWithSearch
        searchColVal={searchColVal}
        setSearchColVal={setSearchColVal}
        columnHeader={columnHeader}
        selectAllComponent={
          selectAllColumn ? (
            <ColumnSelectAll
              columns={columns}
              disableColumnSelection={disableColumnSelection}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              threeColumn={threeColumn}
            />
          ) : undefined
        }
      />
      <List
        sx={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}
        disablePadding
      >
        {columnsToDisplay?.map((column) => {
          const subColOneIds = column.subColOne.map(({ id }) => id);
          const subColTwoIds =
            threeColumn &&
            column.subColOne
              .map((sco) => sco.subColTwo!.map(({ id }) => id))
              .flat();

          const checked = subColTwoIds
            ? isEverythingSelected(subColTwoIds, selectedItems.subColTwo)
            : isEverythingSelected(subColOneIds, selectedItems.subColOne);

          const someScoSelected = isSomeSelected(
            subColOneIds,
            selectedItems.subColOne,
          );

          return (
            <ListItem
              key={column.id}
              handleSelect={() =>
                handleSelectColumn
                  ? handleSelectColumn(column, () => handleToggleColumn(column))
                  : handleToggleColumn(column)
              }
              handleMouseEnter={() => setSelectedColumn(column)}
              checked={checked}
              id={column.id}
              name={column.name}
              isActive={selectedColumn?.id === column.id}
              indeterminate={someScoSelected && !checked}
              shouldBeDisabled={column.subColOne.length === 0}
              disableSelection={disableColumnSelection}
            />
          );
        })}
      </List>
    </Paper>
  );
};

export default ColumnList;
