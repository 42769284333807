import { DeepPartial } from 'react-hook-form';

import { User } from 'services/api/general';
import { DeepNullable } from 'types';

const userFormDefaultVals: DeepNullable<DeepPartial<User>> = {
  first_name: '',
  last_name: null,
  email: '',
  username: '',
  password: '',
  user_channel_id: null,
  group: null,
  sites: [],
  company: null,
  resellers: [],
  preferred_language: 'en',
  notification_settings: {
    enabled: true,
    system_notifications: true,
    cameras: [],
  },
};

export default userFormDefaultVals;
