import React from 'react';

import { Box, Divider, List, Stack } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import FactoryIcon from '@mui/icons-material/Factory';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import UserNotifications from 'components/User/UserNotifications';
import CommonPermissions from 'permissions/CommonPermissions';
import AuthorisedRoutes from '../../../AuthorisedRoutes';
import sections from './sections';
import useGetCurrentSection from './hooks/useGetSection';
import SidebarMenuItem from './SidebarMenuItem';
import CurUserSettings from './CurUserSettings';

const Sidebar = () => {
  const page = useGetCurrentSection('page');
  const section = useGetCurrentSection('section');

  const currentSection = sections.find((s) => s.path === section);

  // For the ones that have subTabs, we want to show the first tab's routes
  const curSectionRoutes = currentSection?.subTabs
    ? currentSection.subTabs[0].routes
    : currentSection?.routes;

  return (
    <List
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
      }}
    >
      <Box>
        {curSectionRoutes?.map(({ url, name, icon, permission }) => (
          <AuthorisedRoutes
            tab_name={currentSection?.tab_access}
            Sidebar
            key={name}
          >
            <SidebarMenuItem
              key={name}
              url={url}
              name={name}
              icon={icon}
              permission={permission}
              page={page}
              section={section}
            />
          </AuthorisedRoutes>
        ))}
      </Box>
      <Stack alignItems="center" spacing={3}>
        <Stack>
          <Divider variant="fullWidth" flexItem />
          <SidebarMenuItem
            url="settings"
            name="Settings"
            icon={<SettingsIcon />}
            permission={CommonPermissions.VIEW_SETTINGS}
            section={section}
            page={page}
          />
          <SidebarMenuItem
            url="company-settings"
            name="Company Settings"
            icon={<FactoryIcon />}
            permission={CommonPermissions.VIEW_COMPANY_SETTINGS}
            section={section}
            page={page}
          />
          <SidebarMenuItem
            url="reseller-settings"
            name="Reseller Settings"
            icon={<HandshakeIcon />}
            permission={CommonPermissions.VIEW_RESELLER_SETTINGS}
            section={section}
            page={page}
          />
          <SidebarMenuItem
            url="operations"
            name="Operations"
            icon={<SmartToyIcon />}
            permission={CommonPermissions.WRITE_OPERATIONS_PAGE}
            section={section}
            page={page}
          />
          <Divider variant="fullWidth" flexItem />
        </Stack>
        <Stack alignItems="center" spacing={1}>
          <UserNotifications />
          <CurUserSettings />
        </Stack>
      </Stack>
    </List>
  );
};

export default Sidebar;
