import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { AlertStatus } from 'types';

export type StatusesFilterState = {
  statuses: AlertStatus[];
};

const initialState: StatusesFilterState = {
  statuses: [],
};

export const statusesFilterSlice = createSlice({
  name: 'statusesFilter',
  initialState,
  reducers: {
    updateStatusesSelection: (state, action: PayloadAction<AlertStatus[]>) => {
      state.statuses = action.payload;
    },
  },
});

export const getSelectedStatusIds = (state: RootState) => {
  if (state.statusesFilter.statuses.length === 0) return undefined;
  return state.statusesFilter.statuses.map((s) => s.id);
};
export const getSelectedStatuses = (state: RootState) =>
  state.statusesFilter.statuses;
export const { updateStatusesSelection } = statusesFilterSlice.actions;

export default statusesFilterSlice.reducer;
