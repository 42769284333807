import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export type SitesFilterState = {
  filteredSiteIds: string[];
};

const initialState: SitesFilterState = {
  filteredSiteIds: [],
};

export const sitesFilterSlice = createSlice({
  name: 'sitesFilter',
  initialState,
  reducers: {
    filterSite: (state, action: PayloadAction<string[]>) => {
      state.filteredSiteIds = action.payload;
    },
  },
});

export const { filterSite } = sitesFilterSlice.actions;

export default sitesFilterSlice.reducer;
export const getSelectedSiteIds = (state: RootState) =>
  state.sitesFilter.filteredSiteIds;
