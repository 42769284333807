import { useMemo } from 'react';

import { createTheme } from '@mui/material';

import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import snakeToCamel from 'utils/handleConvertSnakeCaseToCamelCase';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subheading1: true;
    tag: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tagButton: true;
  }
}

const PARTNER_THEME = {
  primary: {
    main: '#2c862f',
    light: '#505392',
    dark: '#000039',
  },
  secondary: {
    main: '#ff9a03',
    light: '#ffcb49',
    dark: '#c66b00',
    contrastText: '#ffffff',
  },
  error: {
    main: '#f44336',
  },
  text: {
    primary: '#000000',
  },
  background: {
    default: '#fafafa',
  },
};

const useMainTheme = () => {
  const platformConfig = usePlatformConfigContext();

  const mainTheme = useMemo(() => {
    const camelThemePalette = snakeToCamel(platformConfig.theme_palette || {});

    return createTheme({
      palette: camelThemePalette || PARTNER_THEME,
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiButton: {
          variants: [
            {
              props: { variant: 'tagButton' },
              style: {
                display: 'block',
                width: '100%',
                borderBottom: '1px solid #80808061',
                textTransform: 'capitalize',
                textAlign: 'left',
                fontSize: '.7rem',
                padding: '.4rem',
              },
            },
          ],
          defaultProps: {
            disableElevation: true,
            disableRipple: true,
            size: 'small',
          },
        },
        MuiTypography: {
          variants: [
            {
              props: {
                variant: 'subheading1',
              },
              style: {
                fontWeight: 'bolder',
                fontSize: 'smaller',
              },
            },
            {
              props: { variant: 'tag' },
              style: {
                padding: '.4rem .6rem',
                borderBottom: '1px solid #80808061',
                display: 'block',
                textAlign: 'center',
              },
            },
          ],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 2,
            },
          },
        },
        MuiTooltip: {
          defaultProps: {
            arrow: true,
            disableInteractive: true,
            placement: 'top',
            slotProps: {
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            },
          },
        },
        MuiDrawer: {
          defaultProps: {
            ModalProps: {
              sx: {
                zIndex: 1250,
              },
            },
            onClick: (e) => {
              e.stopPropagation();
            },
          },
        },
      },
      typography: {
        fontFamily: 'sans-serif',
        fontWeightLight: 300,
        fontWeightRegular: 400,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 2000,
          xxxl: 2500,
        },
      },
    });
  }, [platformConfig.theme_palette]);

  return mainTheme;
};

export default useMainTheme;
