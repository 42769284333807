const VmPermissions = {
  // VIEW
  VIEW_VM_TAB: 'VAV01',
  VIEW_VM_DASHBOARD: 'VARD1',
  VIEW_VM_MANAGEMENT: 'VARM1',
  VIEW_VM_CONFIG: 'VARC1',
  VIEW_VM_SETTINGS: 'VARS1',
  VIEW_VM_LIST_PLATES: 'VARL1',
  VIEW_VM_AUTHORIZATIONS: 'VARL2',
  VIEW_VM_RECORDS: 'VARL3',
  VIEW_VM_CONFIGURATION: 'VARAV',

  // WRITE
  DOWNLOAD_VM_REPORT: 'VARAP',
  TEST_VM_PERIODIC_REPORT: 'VARAT',
  CREATE_VM_LICENSE_PLATE: 'VARAL',
  CREATE_VM_AUTHORIZATION: 'VARAA',
  CREATE_VM_RECORDS: 'VARAR',
  TRANSFER_RECORDS: 'ATRA1',

  // UPDATE
  UPDATE_VM_LICENSE_PLATE: 'VARUL',
  UPDATE_VM_AUTHORIZATION: 'VARUA',
  UPDATE_VM_CONFIG: 'VARUC',
  UPDATE_VM_RECORD: 'VARUR',

  // DELETE
  DELETE_VM_LICENSE_PLATE: 'VARDL',
  DELETE_VM_RECORD: 'VARDR',
  DELETE_VM_AUTHORIZATION: 'VARDU',
};

export default VmPermissions;
