import * as Sentry from '@sentry/react';
import { replayIntegration } from '@sentry/react';

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://0b14af61135168003816afb42bf90ca5@o4506346036920320.ingest.sentry.io/4506363631370240',
    tracePropagationTargets: ['localhost', 'ailytics-backend.ailytics.ai'],
    integrations: [
      new Sentry.BrowserTracing(),
      replayIntegration({
        maxReplayDuration: 1000 * 60, // 1 minute
        sessionSampleRate: 0.1, // 10% of sessions
        maskAllInputs: true,
        maskAllText: false,
        blockAllMedia: false,
        errorSampleRate: 1, // 100% of errors
      }),
    ],
    tracesSampleRate: 0.5, //  Capture 50% of the transactions
    replaysSessionSampleRate: 0.1, // 10% of sessions
    replaysOnErrorSampleRate: 1, // 100% of errors
  });
};
