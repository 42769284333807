import React from 'react';

import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface IProps {
  isLoading: boolean;
  btnProps: ButtonProps;
  children: React.ReactNode;
}

const ButtonWithLoading = ({
  isLoading,
  btnProps: { onClick, ...otherBtnProps },
  children,
}: IProps) => (
  <Button
    onClick={(e) => !isLoading && onClick && onClick(e)}
    {...otherBtnProps}
  >
    {isLoading ? (
      <CircularProgress size={20} sx={{ color: 'white' }} />
    ) : (
      children
    )}
  </Button>
);

export default ButtonWithLoading;
