import React, { useLayoutEffect, useState } from 'react';

import useMeasure from 'react-use-measure';

interface IProps {
  height?: number;
  maxWidth?: number;
  src: string;
  alt: string;
}

const ResizedImage = ({ height, maxWidth, src, alt }: IProps) => {
  const [ref, bounds] = useMeasure();

  const [imgDimensions, setImgDimensions] = useState<{
    height: 'auto' | number;
    width: 'auto' | number;
  }>({
    height: height || 'auto',
    width: 'auto',
  });

  useLayoutEffect(() => {
    if (maxWidth && bounds.width > maxWidth) {
      setImgDimensions({
        height: 'auto',
        width: maxWidth,
      });
    }
  }, [bounds.width]);

  return (
    <img
      src={src}
      style={{
        objectFit: 'contain',
      }}
      alt={alt}
      ref={ref}
      height={imgDimensions.height}
      width={imgDimensions.width}
    />
  );
};

export default ResizedImage;
