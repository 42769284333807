import React from 'react';

import { List, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ListItem from '../ListItem';
import type {
  Column,
  HandleSelectSbuColOne,
  RenderPrimary,
  SelectedItems,
  SubColOne,
} from '../../types';
import ColumnHeader from '../ColumnHeader';
import useToggleSubColOne from './useToggleSubColOne';
import { isEverythingSelected, isSomeSelected } from '../../utils';
import NoOptionMsg from '../NoOptionMsg';

interface IProps {
  selectedColumn?: Column;
  setSelectedSubColOne: React.Dispatch<
    React.SetStateAction<SubColOne | undefined>
  >;
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
  handleSelectSubColOne?: HandleSelectSbuColOne;
  subColOneHeader: string;
  threeColumn?: boolean;
  selectedSubColOne?: SubColOne;
  disableSubColOneSelection?: boolean;
  renderSubColOnePrimary?: RenderPrimary;
}

const SubColOneList = ({
  selectedColumn,
  setSelectedSubColOne,
  selectedItems,
  setSelectedItems,
  handleSelectSubColOne,
  threeColumn,
  subColOneHeader,
  selectedSubColOne,
  disableSubColOneSelection,
  renderSubColOnePrimary,
}: IProps) => {
  const { t } = useTranslation('common');
  const handleToggleSubColOne = useToggleSubColOne({
    selectedItems,
    setSelectedItems,
  });

  return (
    <Paper sx={{ height: '100%' }}>
      <ColumnHeader>{subColOneHeader}</ColumnHeader>
      <List
        sx={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}
        disablePadding
      >
        {selectedColumn?.subColOne.length === 0 && (
          <NoOptionMsg
            message={`${t(
              'common.selectedSiteHasNo',
              'Selected site has no',
            )} ${subColOneHeader.toLowerCase()}`}
          />
        )}

        {selectedColumn?.subColOne.map((subColOne) => {
          const subColTwoIds = subColOne.subColTwo?.map(({ id }) => id);

          const shouldBeChecked =
            subColTwoIds && threeColumn
              ? isEverythingSelected(subColTwoIds, selectedItems.subColTwo)
              : selectedItems.subColOne.includes(subColOne.id);

          const someSctSelected =
            subColTwoIds &&
            isSomeSelected(subColTwoIds, selectedItems.subColTwo);

          return (
            <ListItem
              key={subColOne.id}
              handleSelect={() =>
                handleSelectSubColOne
                  ? handleSelectSubColOne(subColOne, selectedColumn, () =>
                      handleToggleSubColOne(subColOne, selectedColumn),
                    )
                  : handleToggleSubColOne(subColOne, selectedColumn)
              }
              handleMouseEnter={() => setSelectedSubColOne(subColOne)}
              id={subColOne.id}
              name={subColOne.name}
              isActive={selectedSubColOne?.id === subColOne.id}
              checked={shouldBeChecked}
              shouldBeDisabled={subColTwoIds?.length === 0 && threeColumn}
              indeterminate={someSctSelected && !shouldBeChecked}
              disableSelection={disableSubColOneSelection}
              renderPrimary={renderSubColOnePrimary}
            />
          );
        })}
      </List>
    </Paper>
  );
};

export default SubColOneList;
