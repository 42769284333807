import React from 'react';

import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useUsersReadNotificationCreateMutation } from 'services/api/general';

interface IProps {
  notificationId: string;
}

const MarkSingleNotiAsRead = ({ notificationId }: IProps) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const [markNotiAsRead] = useUsersReadNotificationCreateMutation();

  const handleMarkNotiAsRead = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    markNotiAsRead({
      userNotificationReadRequest: {
        notification_id: notificationId,
      },
    })
      .unwrap()
      .then(() =>
        enqueueSnackbar(
          t(
            'user.notificationMarkedAsRead',
            'Notification has been marked as read',
          ),
          {
            variant: 'success',
          },
        ),
      )
      .catch((err) =>
        enqueueSnackbar(
          JSON.stringify(err) ||
            t('somethingWentWrong', 'Something went wrong'),
          {
            variant: 'error',
          },
        ),
      );
  };

  return (
    <Tooltip title={t('user.markAsRead', 'Mark as read')}>
      <IconButton
        onClick={handleMarkNotiAsRead}
        id="mark-as-read"
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          opacity: '0',
          transition: 'all .4s',
          background: 'gainsboro',
          '&:hover': {
            background: 'gainsboro',
          },
        }}
      >
        <MarkEmailReadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default MarkSingleNotiAsRead;
