import React, { PropsWithoutRef } from 'react';

import {
  CardHeader as MuiCardHeader,
  CardHeaderProps,
  ThemeProvider,
  createTheme,
  Theme,
} from '@mui/material';

const newThemeComponent = {
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        fontSize: 'smaller',
        fontWeight: 'bolder',
      },
    },
  },
};

interface SettingsCardHeaderProps extends PropsWithoutRef<CardHeaderProps> {
  flexGrow?: number;
}

const SettingsCardHeader = ({
  flexGrow = 0,
  sx,
  ...props
}: SettingsCardHeaderProps) => (
  <ThemeProvider
    theme={(outterTheme: Theme) =>
      createTheme({
        ...outterTheme,
        components: { ...outterTheme.components, ...newThemeComponent },
      })
    }
  >
    <MuiCardHeader
      {...props}
      sx={{
        bgcolor: 'lightgray',
        '& .MuiCardHeader-content': {
          flexGrow,
        },
        ...sx,
      }}
    />
  </ThemeProvider>
);

export default SettingsCardHeader;
