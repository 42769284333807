import { useState, useEffect } from 'react';

interface UseSearchDataProps<D> {
  data: D[] | undefined;
  lookupField: keyof D;
}

const useSearchData = <D>({ data, lookupField }: UseSearchDataProps<D>) => {
  const [searchResult, setSearchResult] = useState(data);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!searchValue) {
      setSearchResult(data);
      return;
    }

    const searchResultSites = data?.filter((s) =>
      (s[lookupField] as string)
        .toLowerCase()
        .includes(searchValue.toLowerCase()),
    );
    setSearchResult(searchResultSites);
  }, [searchValue, data]);

  return [searchResult, setSearchResult, searchValue, setSearchValue] as const;
};

export default useSearchData;
