import React from 'react';

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClick: () => void;
}

const ProfileButton = ({ onClick }: IProps) => {
  const { t } = useTranslation('common');
  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon>
        <PersonOutlineIcon fontSize="medium" />
      </ListItemIcon>
      <ListItemText
        primary={t('common.profile', 'Profile')}
        sx={{ fontSize: '1.2rem' }}
      />
    </ListItemButton>
  );
};

export default ProfileButton;
