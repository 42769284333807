const charCodes = [
  [65, 90], // uppercase
  [97, 122], // lowercase
  [48, 57], // numbers
  [33, 47], // !"#$%&'()*+,-./
  [58, 64], // :;<=>?@
  [91, 91], // [
  [93, 96], // ]#_`
  [123, 126], // {|}~
];

const getRandomNumber = (min: number, max: number): number => {
  const randomBuffer = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  const randomNum = randomBuffer[0] / (0xffffffff + 1);
  return Math.floor(randomNum * (max - min + 1)) + min;
};

const getRandomCharacter = (charCodeIndex: number): string => {
  const code = getRandomNumber(
    charCodes[charCodeIndex][0],
    charCodes[charCodeIndex][1],
  );
  return String.fromCharCode(code);
};

const generateNewPassword = (): string => {
  let newPassword = '';
  for (let i = 0; i < 12; i += 1) {
    // guarantee one of each lowercase, uppercase, number, and symbol
    if (i < 4) {
      newPassword += getRandomCharacter(i);
    } else {
      newPassword += getRandomCharacter(
        getRandomNumber(0, charCodes.length - 1),
      );
    }
  }
  return newPassword;
};

export default generateNewPassword;
