import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { Trigger } from 'types';

export type TriggersFilterState = {
  triggers: Trigger[];
};

const initialState: TriggersFilterState = {
  triggers: [],
};

export const triggersFilterSlice = createSlice({
  name: 'groupsFilter',
  initialState,
  reducers: {
    updateTriggerSelection: (state, action: PayloadAction<Trigger[]>) => {
      state.triggers = action.payload;
    },
  },
});

export const getSelectedTriggerIds = (state: RootState) => {
  if (state.triggersFilter.triggers.length === 0) return undefined;
  return state.triggersFilter.triggers.map((t) => t.id);
};
export const getSelectedTriggers = (state: RootState) =>
  state.triggersFilter.triggers;
export const { updateTriggerSelection } = triggersFilterSlice.actions;

export default triggersFilterSlice.reducer;
