import React, { StrictMode, lazy } from 'react';

import { createRoot } from 'react-dom/client';
// this import fixes the styled_default vite error
import '@mui/material/styles/styled';
import 'chartjs-adapter-moment';
import { BrowserRouter, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Chart, registerables } from 'chart.js';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { I18nextProvider } from 'react-i18next';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';

import SnackbarProviderWithDismiss from 'components/SnackbarProviderWithDismiss';
import PlatformConfigContextProvider from 'contexts/PlatformConfigContext';

import './index.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import initYupLocale from 'libs/yup';
import InitialPageLoad from 'components/InitialPageLoad';
import FrPlatformCtxProvider from 'contexts/frPlatformCtx';
import MainThemeProvider from 'components/MainThemeProvider';
import SuspenseWithLoading from 'components/common/SuspenseWithLoading';
import ConfirmationDialogProvider from 'contexts/ConfirmationDialogProvider';
import CustomRoutes from 'components/common/CustomRoutes';
import SocketContextProvider from 'socket';
import { SnackbarUtilsConfigurator } from 'components/SnackbarUtilsConfigurator';
import WithPermissions from 'components/common/Permissions';
import CommonPermissions from 'permissions/CommonPermissions';
import NoAccess from 'components/UnauthorizedAccess';

import AuthorisedRoutes, { ProtectedRoute } from './AuthorisedRoutes';
import i18n from './i18n';

import store from './store';

import Header from './components/common/header';
import { initSentry } from './utils/sentry';

const AlertRoutes = lazy(() => import('tabs/alerts'));
const FrRoutes = lazy(() => import('tabs/facialRecognition'));
const VehicleAccessRoutes = lazy(() => import('tabs/vehicleAccess'));
const AuthRoutes = lazy(() => import('components/Auth'));
const InspectionRoutes = lazy(() => import('tabs/inspection'));
const FootfallRoutes = lazy(() => import('tabs/footfall'));
const WorkhourTrackingRoutes = lazy(() => import('tabs/workhourTracking'));
const VehicleManagementRoutes = lazy(() => import('tabs/vehicleManagement'));
const Permissions = lazy(() => import('tabs/permission'));
const SettingsPage = lazy(() => import('components/Settings'));
const CompanySettingsPage = lazy(
  () => import('components/Settings/CompanySettingsPage'),
);
const ResellerSettingsPage = lazy(
  () => import('components/Settings/ResellerSettingsPage'),
);
const CameraHealthCheckRoutes = lazy(() => import('tabs/cameraHealthCheck'));
const OperationsPage = lazy(() => import('tabs/operationsPage'));
const UsecaseUsagePage = lazy(() => import('tabs/usecaseUsage'));

if (import.meta.env.MODE === 'production') {
  initSentry(); // init sentry only in production
}

Chart.register(ChartDataLabels);
Chart.register(zoomPlugin);
Chart.register(...registerables);

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY || '');

const container = document.getElementById('root');
const root = createRoot(container!);

initYupLocale();

root.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: 'Start Date', end: 'End Date' }}
      >
        <SuspenseWithLoading>
          <FrPlatformCtxProvider>
            <BrowserRouter>
              <Provider store={store}>
                <SocketContextProvider>
                  <PlatformConfigContextProvider>
                    <MainThemeProvider>
                      <InitialPageLoad />
                      <SnackbarProviderWithDismiss>
                        <ConfirmationDialogProvider>
                          <SnackbarUtilsConfigurator />
                          <CustomRoutes>
                            <Route path="/*" element={<AuthRoutes />} />
                            <Route
                              path="/"
                              element={
                                <ProtectedRoute>
                                  <Header />
                                </ProtectedRoute>
                              }
                            >
                              <Route
                                index
                                element={<Navigate to="/alerts" replace />}
                              />
                              {/* Tabs */}
                              <Route
                                path="/alerts/*"
                                element={
                                  <AuthorisedRoutes tab_name="alerts">
                                    <AlertRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/people/*"
                                element={
                                  <AuthorisedRoutes tab_name="people_recognition">
                                    <FrRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/vehicles/*"
                                element={
                                  <AuthorisedRoutes tab_name="vehicular_access">
                                    <VehicleAccessRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/inspection/*"
                                element={
                                  <AuthorisedRoutes tab_name="inspection">
                                    <InspectionRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/vehicle-management/*"
                                element={
                                  <AuthorisedRoutes tab_name="vehicle_management">
                                    <VehicleManagementRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/footfall/*"
                                element={
                                  <AuthorisedRoutes tab_name="footfall">
                                    <FootfallRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/workhour-tracking/*"
                                element={
                                  <AuthorisedRoutes tab_name="workhour">
                                    <WorkhourTrackingRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/camera-health/*"
                                element={
                                  <AuthorisedRoutes tab_name="camera_health_check">
                                    <CameraHealthCheckRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              {/* Standalone pages */}
                              <Route
                                path="/permissions"
                                element={<Permissions />}
                              />
                              <Route
                                path="/:tab/operations"
                                element={
                                  <WithPermissions
                                    permission={
                                      CommonPermissions.WRITE_OPERATIONS_PAGE
                                    }
                                    onPermissionFailed={<NoAccess />}
                                  >
                                    <OperationsPage />
                                  </WithPermissions>
                                }
                              />
                              <Route
                                path="/:tab/settings"
                                element={
                                  <WithPermissions
                                    permission={CommonPermissions.VIEW_SETTINGS}
                                    onPermissionFailed={<NoAccess />}
                                  >
                                    <SettingsPage />
                                  </WithPermissions>
                                }
                              />
                              <Route
                                path="/:tab/company-settings"
                                element={
                                  <WithPermissions
                                    permission={
                                      CommonPermissions.VIEW_COMPANY_SETTINGS
                                    }
                                    onPermissionFailed={<NoAccess />}
                                  >
                                    <CompanySettingsPage />
                                  </WithPermissions>
                                }
                              />
                              <Route
                                path="/:tab/reseller-settings"
                                element={
                                  <WithPermissions
                                    permission={
                                      CommonPermissions.VIEW_RESELLER_SETTINGS
                                    }
                                    onPermissionFailed={<NoAccess />}
                                  >
                                    <ResellerSettingsPage />
                                  </WithPermissions>
                                }
                              />
                              {/* Standalone pages */}
                              <Route
                                path="/permissions"
                                element={<Permissions />}
                              />
                              <Route
                                path="/:tab/settings"
                                element={<SettingsPage />}
                              />
                              <Route
                                path="/:tab/company-settings"
                                element={<CompanySettingsPage />}
                              />
                              <Route
                                path="/:tab/reseller-settings"
                                element={<ResellerSettingsPage />}
                              />
                              {/* Standalone pages */}
                              <Route
                                path="/permissions"
                                element={<Permissions />}
                              />
                              <Route
                                path="/:tab/settings"
                                element={<SettingsPage />}
                              />
                              <Route
                                path="/:tab/company-settings"
                                element={<CompanySettingsPage />}
                              />
                              <Route
                                path="/:tab/reseller-settings"
                                element={<ResellerSettingsPage />}
                              />

                              <Route
                                path="/usecase-usage/*"
                                element={<UsecaseUsagePage />}
                              />
                            </Route>
                          </CustomRoutes>
                        </ConfirmationDialogProvider>
                      </SnackbarProviderWithDismiss>
                    </MainThemeProvider>
                  </PlatformConfigContextProvider>
                </SocketContextProvider>
              </Provider>
            </BrowserRouter>
          </FrPlatformCtxProvider>
        </SuspenseWithLoading>
      </LocalizationProvider>
    </I18nextProvider>
  </StrictMode>,
);
