// Based on user notification settings, send notification to user
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import { NotificationType } from './types';

const useSendNotification = () => {
  const { data: curUser } = useUsersCurrentRetrieveQuery();
  const { enqueueSnackbar } = useSnackbar();

  const handleSendNotification = useCallback(
    (event: NotificationType) => {
      try {
        const audio = new Audio('/sounds/notification-sound.mp3');
        audio.play();
      } catch (e) {
        // NOTE: This catch block is mainly to reduce the number of sentry errors.
        // If the sound fails to play for the user, so be it. We don't really have
        // a need or a way to handle this error
      }

      enqueueSnackbar(event.body, {
        variant: 'info',
        autoHideDuration: 10000,
      });

      if (
        Notification.permission === 'granted' &&
        curUser?.notification_settings.system_notifications
      ) {
        const notification = new Notification(event.body);
        notification.onclick = () => window.focus();
      }
    },
    [curUser],
  );

  return handleSendNotification;
};

export default useSendNotification;
