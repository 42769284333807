import { useUsersCurrentRetrieveQuery } from 'services/api/general';

interface UseHasPermissionProps {
  permission?: string | string[];
  superuser?: boolean;
  owner?: number;
  manualAuthorisation?: {
    permission: string;
    isAuthorised: () => boolean;
  };
}

const useHasPermission = ({
  permission,
  superuser,
  owner,
  manualAuthorisation,
}: UseHasPermissionProps) => {
  const { data } = useUsersCurrentRetrieveQuery();

  const permissionArray = Array.isArray(permission) ? permission : [permission];
  const curUserPerms = data?.perms;

  const userHasPermission = curUserPerms?.some((perm) =>
    permissionArray.includes(perm),
  );

  let hasPermission =
    userHasPermission ||
    data?.is_superuser ||
    (!permission && !superuser) ||
    (owner && data?.id && owner === data?.id);

  if (
    manualAuthorisation &&
    curUserPerms?.includes(manualAuthorisation.permission) &&
    !hasPermission
  ) {
    // If a certain permission code is persent, we call a function that determines the authorisation
    hasPermission = manualAuthorisation.isAuthorised();
  }

  return Boolean(hasPermission);
};

export default useHasPermission;
