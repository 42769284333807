import React, { PropsWithoutRef } from 'react';

import { Box } from '@mui/material';

import {
  type Languages,
  AccountState,
  useUsersCurrentRetrieveQuery,
  type User,
} from 'services/api/general';
import type { FormFieldsType, ResourceFormProps } from 'types';
import {
  S3ImageField,
  SelectFieldRenderer,
  TextFieldRenderer,
} from 'components/common/Forms/FieldRenderers';
import PasswordFieldRenderer from 'components/common/Forms/FieldRenderers/PasswordFieldRenderer';
import i18n from 'i18n';

const FIELDS: FormFieldsType<User>[] = [
  {
    title: i18n.t('settings.firstName', 'First Name', { ns: 'common' }),
    caption: i18n.t(
      'settings.firstNameDescription',
      'The first name of the user',
      { ns: 'common' },
    ),
    fieldName: 'first_name',
    type: 'text',
    required: true,
  },
  {
    title: i18n.t('settings.lastName', 'Last Name', { ns: 'common' }),
    caption: i18n.t(
      'settings.lastNameDescription',
      'The last name of the user',
      { ns: 'common' },
    ),
    fieldName: 'last_name',
    type: 'text',
  },
  {
    title: i18n.t('settings.email', 'E-Mail', { ns: 'common' }),
    caption: i18n.t(
      'settings.emailDescription',
      'The e-mail address of the user',
      { ns: 'common' },
    ),
    fieldName: 'email',
    type: 'text',
    required: true,
  },
  {
    title: i18n.t('settings.username', 'Username', { ns: 'common' }),
    caption: i18n.t(
      'settings.usernameDescription',
      'The username the user will log in with',
      { ns: 'common' },
    ),
    fieldName: 'username',
    type: 'text',
    required: true,
  },
];

interface LanguageChoice {
  label: string;
  value: Languages;
}
const LANGUAGE_CHOICES: LanguageChoice[] = [
  { label: 'English', value: 'en' },
  { label: 'Japanese', value: 'jp' },
  { label: 'Mandarin', value: 'zh' },
];

interface IProps extends PropsWithoutRef<ResourceFormProps<User>> {
  userId?: number;
  userAccountState?: AccountState;
}

const UserFormBasicConfigSection = ({
  control,
  userId,
  setValue,
  userAccountState,
}: IProps) => {
  const { data: currentUser } = useUsersCurrentRetrieveQuery();
  const isCurrentUser = userId === currentUser?.id;

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gap: 2,
      }}
    >
      {isCurrentUser && (
        <S3ImageField
          context="avatar"
          control={control}
          name="avatar"
          label={i18n.t('settings.profilePicture', 'Profile Picture', {
            ns: 'common',
          })}
        />
      )}
      {FIELDS.map(({ title, caption, type, fieldName, required }) => (
        <Box key={fieldName} sx={{ display: 'grid' }}>
          <TextFieldRenderer
            name={fieldName}
            control={control}
            label={title}
            subtitle={caption}
            type={type}
            required={required}
          />
        </Box>
      ))}
      <PasswordFieldRenderer
        control={control}
        name="password"
        label={i18n.t('settings.password', 'Password', { ns: 'common' })}
        subtitle={i18n.t(
          'settings.passwordUserLoginWith',
          'The password the user will log in with',
          { ns: 'common' },
        )}
        required={!userId}
        creatingNewUser={!userId}
        setValue={setValue}
        userAccountState={userAccountState}
      />
      <TextFieldRenderer
        name="user_channel_id"
        control={control}
        label={i18n.t('settings.userChannelId', 'User Channel ID', {
          ns: 'common',
        })}
        subtitle={i18n.t(
          'settings.userTelegramIdToIntegrateWithAlerts',
          'User’s platform-telgram ID that will be used to interact with the alerts',
          { ns: 'common' },
        )}
        type="text"
      />
      {isCurrentUser && (
        <SelectFieldRenderer
          label="Preferred Language"
          subtitle="Your chosen language for the app"
          name="preferred_language"
          control={control}
          items={LANGUAGE_CHOICES}
        />
      )}
    </Box>
  );
};

export default UserFormBasicConfigSection;
