import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { RootState } from 'store';

interface WorkhourFilterState {
  selectedLabels: string[];
}

const initialState: WorkhourFilterState = {
  selectedLabels: [],
};

export const workhourDashboardFiltersSlice = createSlice({
  name: 'workhourFilters',
  initialState,
  reducers: {
    updateSelectedLabels: (state, action: PayloadAction<string[]>) => {
      state.selectedLabels = action.payload;
    },
  },
});

export const { updateSelectedLabels } = workhourDashboardFiltersSlice.actions;

export default workhourDashboardFiltersSlice.reducer;

export const useGetWorkhourFilters = () =>
  useSelector((state: RootState) => state.workhourFilter);

export const useUpdateWorkhourFilters = () => {
  const dispatch = useDispatch();

  const handleUpdateSelectedLabels = (newLabels: string[]) => {
    dispatch(updateSelectedLabels(newLabels));
  };

  return {
    handleUpdateSelectedLabels,
  };
};
