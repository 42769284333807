import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import * as queryString from 'query-string';
import RefreshTokenResponse from './types';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  paramsSerializer: (params) => queryString.stringify(params),
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem('token');

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = (await baseQuery(
      {
        url: 'auth/token/refresh/',
        method: 'POST',
        body: { refresh: localStorage.getItem('refresh') },
      },
      api,
      extraOptions,
    )) as QueryReturnValue<RefreshTokenResponse>;

    if (!refreshResult.error && refreshResult.data) {
      localStorage.setItem('token', refreshResult.data.access);
      result = await baseQuery(args, api, extraOptions);
    } else if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  }

  return result;
};

export const alertStatsTags = [
  'AlertCounts',
  'AlertCountsByBlock',
  'AlertCountsByDate',
  'AlertCountsRolling',
];

export const tags = [
  'Site',
  'Camera',
  'Alert',
  'AlertBase',
  'CameraBase',
  'CameraSudoSettings',
  'User',
  'AlertComments',
  'Group',
  'AywaProcessingNodes',
  'CurrentUser',
  'FrCalibration',
  'FrPeople',
  'FrAuthorizations',
  'VaCalibration',
  'VaRecords',
  'VaRecordsInfo',
  'LicensePlates',
  'LicensePlatesAuth',
  'Reseller',
  'CompanyUser',
  'Company',
  'ResellerUser',
  'Zone',
  'UserUnreadNotificationsCount',
  'UserNotifications',
  'ApnCameraRelations',
  ...alertStatsTags,
];

const emptySplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: tags,
});

export default emptySplitApi;
