import { FormControlLabel, TextField, Select, styled } from '@mui/material';

export const CustomFormControlLabel = styled(FormControlLabel)({
  margin: 8,
});

export const CustomTextField = styled(TextField)({
  margin: 0,
});

export const CustomDropdownField = styled(Select)({});
