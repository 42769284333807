import { useState } from 'react';

import { SelectedItems } from '../types';

const useSelectedItems = () => {
  const [selectedItems, setSelectedItems] = useState<SelectedItems>({
    column: [],
    subColOne: [],
    subColTwo: [],
  });

  return { selectedItems, setSelectedItems };
};

export default useSelectedItems;
