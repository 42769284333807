import React from 'react';

import {
  ListItem,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import { formatDistance } from 'date-fns';
import { useNavigate } from 'react-router';

import {
  useUsersReadNotificationCreateMutation,
  type UserNotificationsRead,
} from 'services/api/general';
import getFormattedDate from 'utils/formatDate';
import MarkSingleNotiAsRead from './MarkSingleNotiAsRead';

const NotificationListItem = ({
  title,
  body,
  timestamp,
  record_id,
  marked_as_read,
  id,
}: UserNotificationsRead) => {
  const [readNotiTrigger] = useUsersReadNotificationCreateMutation();

  const navigate = useNavigate();

  const handleRedirectToNotification = () => {
    const url = `alerts/summary/${record_id}`;
    navigate(url);
    readNotiTrigger({
      userNotificationReadRequest: {
        notification_id: id,
      },
    });
  };

  return (
    <ListItem
      disablePadding
      sx={{
        py: 1,
        '&:hover #mark-as-read': {
          opacity: 1,
        },
        bgcolor: !marked_as_read ? '#8080801a' : 'unset',
      }}
      onClick={handleRedirectToNotification}
    >
      <ListItemButton>
        <Stack width="100%" spacing={1}>
          <Stack alignItems="center" spacing={1} direction="row">
            <AddAlertRoundedIcon
              sx={{
                color: 'rgba(0, 0, 0, 0.54)',
              }}
            />
            <Typography fontWeight="bold">{title}</Typography>
            <Tooltip
              sx={{ marginLeft: 'auto' }}
              title={getFormattedDate(new Date(timestamp))}
            >
              <Typography
                sx={{ marginLeft: 'auto !important' }}
                variant="subtitle2"
              >
                {formatDistance(new Date(), new Date(timestamp))} ago
              </Typography>
            </Tooltip>
          </Stack>
          <Typography
            color={(theme) => theme.palette.text.primary}
            variant="body2"
          >
            {body}
          </Typography>
        </Stack>
        {!marked_as_read && <MarkSingleNotiAsRead notificationId={id} />}
      </ListItemButton>
    </ListItem>
  );
};

export default NotificationListItem;
