import React, { useRef, useState } from 'react';

import { List, ListItem, Box, Paper, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUsersCurrentRetrieveQuery } from 'services/api/general';

import UpdateUserFormDialog from 'components/Settings/UserForm/UpdateUserFormDialog/UpdateUserFormDialog';
import LogoutButton from './LogoutButton';
import ProfileButton from './ProfileButton';
import CustomPopover from '../CustomPopover';
import ProfileAvatar from '../../User/ProfileAvatar';
import { PopoverInstanceType } from '../CustomPopover/CustomPopover';

const CurUserSettings = () => {
  const { t } = useTranslation('common');
  const { data: userData } = useUsersCurrentRetrieveQuery();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const popoverRef = useRef<PopoverInstanceType>(null);

  return (
    <>
      <CustomPopover
        popoverProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        ref={popoverRef}
        trigger={
          <Button variant="text">
            <ProfileAvatar
              avatar={userData?.avatar}
              username={userData?.first_name}
            />
          </Button>
        }
      >
        <Paper sx={{ borderRadius: '200px' }}>
          <Box sx={{ width: '12rem', bgcolor: 'background.paper' }}>
            {userData && (
              <List>
                <ListItem disablePadding disableGutters>
                  <ProfileButton
                    onClick={() => {
                      if (popoverRef.current) {
                        popoverRef.current.close();
                      }
                      setDialogIsOpen(true);
                    }}
                  />
                </ListItem>
                <ListItem disablePadding disableGutters>
                  <LogoutButton />
                </ListItem>
              </List>
            )}
          </Box>
        </Paper>
      </CustomPopover>
      {userData && (
        <UpdateUserFormDialog
          isOpen={dialogIsOpen}
          closeDialog={() => setDialogIsOpen(false)}
          hidePermissionSection
          title={t('common.yourProfileSettings', 'Your Profile Settings')}
          userId={userData.id}
        />
      )}
    </>
  );
};

export default CurUserSettings;
