import emptySplitApi from 'services/api/ailyssaBaseApi';

import {
  AywaProcessingNodesListApiResponse,
  AywaProcessingNodesListApiArg,
  AywaProcessingNodesCreateApiResponse,
  AywaProcessingNodesCreateApiArg,
  AywaProcessingNodesRetrieveApiResponse,
  AywaProcessingNodesRetrieveApiArg,
  AywaProcessingNodesUpdateApiResponse,
  AywaProcessingNodesUpdateApiArg,
  AywaProcessingNodesPartialUpdateApiResponse,
  AywaProcessingNodesPartialUpdateApiArg,
  AywaProcessingNodesDestroyApiResponse,
  AywaProcessingNodesDestroyApiArg,
  AywaProcessingNodesTokenGenerateRetrieveApiResponse,
  AywaProcessingNodesTokenGenerateRetrieveApiArg,
  AywaProcessingNodesCameraProcessingNodesRelationsListApiArg,
  AywaProcessingNodesCameraProcessingNodesRelationsListApiResponse,
  AywaProcessingNodesKillCreateApiArg,
} from './types';

const aywaProcessingNodeApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    aywaProcessingNodesList: build.query<
      AywaProcessingNodesListApiResponse,
      AywaProcessingNodesListApiArg
    >({
      query: (queryArg) => ({
        url: '/apn/deployments/',
        params: {
          apn_name: queryArg.name.length > 0 ? queryArg.name : undefined,
          active_apn_health: queryArg.activeApnHealth
            ? queryArg.activeApnHealth
            : undefined,
          active_apn_system: queryArg.activeApnSystem
            ? queryArg.activeApnSystem
            : undefined,
        },
      }),
      providesTags: ['AywaProcessingNodes'],
    }),
    aywaProcessingNodesCreate: build.mutation<
      AywaProcessingNodesCreateApiResponse,
      AywaProcessingNodesCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/apn/deployments/',
        method: 'POST',
        body: queryArg.aywaProcessingNode,
      }),
      invalidatesTags: [
        'AywaProcessingNodes',
        'ResellerAywaProcessingNodes',
        'ApnCameraRelations',
      ],
    }),
    aywaProcessingNodesRetrieve: build.query<
      AywaProcessingNodesRetrieveApiResponse,
      AywaProcessingNodesRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/apn/deployments/${queryArg.id}/` }),
      providesTags: (result) =>
        result
          ? [{ type: 'AywaProcessingNodes', id: result.id }]
          : ['AywaProcessingNodesamera'],
    }),
    aywaProcessingNodesUpdate: build.mutation<
      AywaProcessingNodesUpdateApiResponse,
      AywaProcessingNodesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/apn/deployments/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.aywaProcessingNode,
      }),
      invalidatesTags: [
        'AywaProcessingNodes',
        'ResellerAywaProcessingNodes',
        'ApnCameraRelations',
      ],
    }),
    aywaProcessingNodesPartialUpdate: build.mutation<
      AywaProcessingNodesPartialUpdateApiResponse,
      AywaProcessingNodesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/apn/deployments/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedAywaProcessingNode,
      }),
      invalidatesTags: [
        'AywaProcessingNodes',
        'ResellerAywaProcessingNodes',
        'ApnCameraRelations',
      ],
    }),
    aywaProcessingNodesDestroy: build.mutation<
      AywaProcessingNodesDestroyApiResponse,
      AywaProcessingNodesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/apn/deployments/${queryArg.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'AywaProcessingNodes',
        'ResellerAywaProcessingNodes',
        'ApnCameraRelations',
      ],
    }),
    aywaProcessingNodesTokenGenerateRetrieve: build.query<
      AywaProcessingNodesTokenGenerateRetrieveApiResponse,
      AywaProcessingNodesTokenGenerateRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: '/apn/deployments/token/generate',
        params: { deployment_id: queryArg.deploymentId },
      }),
    }),
    aywaProcessingNodesCameraProcessingNodesRelationsList: build.query<
      AywaProcessingNodesCameraProcessingNodesRelationsListApiResponse,
      AywaProcessingNodesCameraProcessingNodesRelationsListApiArg
    >({
      query: (queryArg) => ({
        url: `/apn/deployments/camera_processing_nodes_relations/`,
        params: {
          camera_ids: queryArg.cameraIds,
          processing_node_id: queryArg.processingNodeId,
        },
      }),
      providesTags: ['ApnCameraRelations'],
    }),
    aywaProcessingNodesKillCreate: build.mutation<
      void,
      AywaProcessingNodesKillCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/apn/deployments/${queryArg.id}/kill/`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useAywaProcessingNodesListQuery,
  useAywaProcessingNodesCreateMutation,
  useAywaProcessingNodesRetrieveQuery,
  useAywaProcessingNodesUpdateMutation,
  useAywaProcessingNodesPartialUpdateMutation,
  useAywaProcessingNodesDestroyMutation,
  useAywaProcessingNodesTokenGenerateRetrieveQuery,
  useAywaProcessingNodesCameraProcessingNodesRelationsListQuery,
  useLazyAywaProcessingNodesTokenGenerateRetrieveQuery,
  useAywaProcessingNodesKillCreateMutation,
} = aywaProcessingNodeApi;

export { aywaProcessingNodeApi };

export * from './types';
