import React, { PropsWithoutRef } from 'react';

import { Stack, Typography } from '@mui/material';

type FormFieldDescriptionProps = {
  title?: string;
  subtitle?: string;
  required?: boolean;
};

export const RequiredAsterisk = () => (
  <Typography
    component="span"
    fontSize="1.2rem"
    sx={{ color: 'red', lineHeight: '.65em' }}
  >
    *
  </Typography>
);

const FormFieldDescription = ({
  title,
  subtitle,
  required,
}: PropsWithoutRef<FormFieldDescriptionProps>) => (
  <>
    {title && (
      <Stack alignItems="center" spacing={0.4} direction="row">
        <Typography variant="body2">{title}</Typography>
        {required && <RequiredAsterisk />}
      </Stack>
    )}
    {subtitle && (
      <Typography variant="caption" sx={{ color: 'gray' }}>
        {subtitle}
      </Typography>
    )}
  </>
);

export default FormFieldDescription;
