import emptySplitApi, { tags } from 'services/api/ailyssaBaseApi';
import {
  AuthOtpGenerateCreateApiResponse,
  AuthOtpGenerateCreateApiArg,
  AuthTokenApikeyGenerateCreateApiResponse,
  AuthTokenApikeyGenerateCreateApiArg,
  AuthTokenGenerateCreateApiResponse,
  AuthTokenGenerateCreateApiArg,
  AuthTokenRefreshCreateApiResponse,
  AuthTokenRefreshCreateApiArg,
  AuthPasswordResetCreateApiResponse,
  AuthPasswordResetCreateApiArg,
  AuthPasswordResetConfirmCreateApiResponse,
  AuthPasswordResetConfirmCreateApiArg,
} from './types';

const authApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    authOtpGenerateCreate: build.mutation<
      AuthOtpGenerateCreateApiResponse,
      AuthOtpGenerateCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/otp/generate/',
        method: 'POST',
        body: queryArg.requestOtpPayload,
      }),
    }),
    authTokenApikeyGenerateCreate: build.mutation<
      AuthTokenApikeyGenerateCreateApiResponse,
      AuthTokenApikeyGenerateCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/token/apikey/generate/',
        method: 'POST',
        body: queryArg.authToken,
      }),
    }),
    authTokenGenerateCreate: build.mutation<
      AuthTokenGenerateCreateApiResponse,
      AuthTokenGenerateCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/token/generate/',
        method: 'POST',
        body: queryArg.tokenRequestPayload,
      }),
      invalidatesTags: tags,
    }),
    authTokenRefreshCreate: build.mutation<
      AuthTokenRefreshCreateApiResponse,
      AuthTokenRefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/token/refresh/',
        method: 'POST',
        body: queryArg.tokenRefresh,
      }),
    }),
    otpProvisionNew: build.mutation<string, void>({
      query: () => ({
        url: '/auth/otp/provision/',
        method: 'POST',
      }),
    }),
    authPasswordResetCreate: build.mutation<
      AuthPasswordResetCreateApiResponse,
      AuthPasswordResetCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/password-reset/',
        method: 'POST',
        body: queryArg.email,
      }),
    }),
    authPasswordResetConfirmCreate: build.mutation<
      AuthPasswordResetConfirmCreateApiResponse,
      AuthPasswordResetConfirmCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/password-reset/confirm/',
        method: 'POST',
        body: queryArg.passwordToken,
      }),
    }),
  }),
});

export const {
  useAuthOtpGenerateCreateMutation,
  useAuthTokenApikeyGenerateCreateMutation,
  useAuthTokenGenerateCreateMutation,
  useAuthTokenRefreshCreateMutation,
  useOtpProvisionNewMutation,
  useAuthPasswordResetCreateMutation,
  useAuthPasswordResetConfirmCreateMutation,
} = authApi;

export * from './types';
