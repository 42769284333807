import { Button, Grid } from '@mui/material';
import React, { PropsWithoutRef, ReactElement } from 'react';

type ModelFooterProps = {
  confirmButton?: ReactElement;
  cancelButtonText: string;
  handleClickCancel: () => void;
};

const ModelFooter = ({
  cancelButtonText,
  confirmButton,
  handleClickCancel,
}: PropsWithoutRef<ModelFooterProps>) => (
  <Grid container sx={{ justifyContent: 'right' }}>
    <Grid item>
      <Button onClick={handleClickCancel}>{cancelButtonText}</Button>
    </Grid>
    {confirmButton && <Grid item>{confirmButton}</Grid>}
  </Grid>
);

export default ModelFooter;
