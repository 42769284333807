import React, { useEffect, useMemo, useState } from 'react';

import { Box, Divider, List, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AlertSummaryPagination from 'components/common/PaginationWithJumpTo';
import {
  type NotificationReadAllTabName,
  useUsersNotificationsListQuery,
  useUsersUnreadNotificationCountRetrieveQuery,
  NotificationReadAllTypeName,
} from 'services/api/general';
import WithLoadingState from 'components/common/WithLoadingState';
import FilterByTabs from './FilterByTabs';
import MarkAllAsRead from './MarkAllAsRead';
import NotificationListItem from './NotificationListItem';
import NotificationsListSkeleton from './NotificationsListSkeleton';
import FilterByType from './FilterByType';

const NotificationPanel = () => {
  const { t } = useTranslation('common');
  const { data: unReadNotiCount = 0 } =
    useUsersUnreadNotificationCountRetrieveQuery();

  const [notiPage, setNotiPage] = useState(1);

  const [selectedFilterTab, setSelectedFilterTab] =
    useState<NotificationReadAllTabName>('all');
  const [selectedFilterType, setSelectedFilterType] =
    useState<NotificationReadAllTypeName>('all');

  const selectedTabExcludesAlerts = useMemo(
    () => selectedFilterTab !== 'all' && selectedFilterTab !== 'alert',
    [selectedFilterTab],
  );

  const { data, isLoading } = useUsersNotificationsListQuery({
    page: notiPage,
    tabName: selectedFilterTab,
    type: selectedFilterType,
  });

  const userNotifications = data?.results;

  useEffect(() => {
    setNotiPage(1);
  }, [selectedFilterTab, selectedFilterType]);

  // Non-alert notifications only have 1 type, so we default to all types
  useEffect(() => {
    if (selectedTabExcludesAlerts) {
      setSelectedFilterType('all');
    }
  }, [selectedFilterTab]);

  return (
    <Paper
      sx={{
        width: '25rem',
        pb: 2,
      }}
    >
      <Stack spacing={1}>
        <Box>
          <Stack
            p={2}
            direction="row"
            justifyContent="space-between"
            gap="10px"
          >
            <FilterByTabs
              selectedFilterTab={selectedFilterTab}
              setSelectedFilterTab={setSelectedFilterTab}
            />
            <FilterByType
              selectedFilterType={selectedFilterType}
              setSelectedFilterType={setSelectedFilterType}
              disabled={selectedTabExcludesAlerts}
            />
            {unReadNotiCount > 0 && (
              <MarkAllAsRead
                selectedFilterTab={selectedFilterTab}
                selectedFilterType={selectedFilterType}
              />
            )}
          </Stack>
          <Divider />
        </Box>
        <List
          sx={{
            height: '60rem',
            borderRadius: '8px',
            maxHeight: '70vh',
            overflowY: 'auto',
          }}
          disablePadding
        >
          <WithLoadingState
            noDataError={t(
              'user.yourNotificationsWillAppearHere',
              'Your notifications will appear here',
            )}
            spinner={<NotificationsListSkeleton />}
            isLoading={isLoading}
            hasData={!!userNotifications?.length}
          >
            {userNotifications?.map((notification) => (
              <NotificationListItem key={notification.id} {...notification} />
            ))}
          </WithLoadingState>
        </List>
        <AlertSummaryPagination
          page={notiPage}
          total_pages={data?.total_pages || 0}
          onChange={setNotiPage}
        />
      </Stack>
    </Paper>
  );
};

export default NotificationPanel;
