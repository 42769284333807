import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface PasswordValidatorProps {
  isVisible: boolean;
  password: string;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    border: '1px solid #dadde9',
    margin: '4px',
    '& .MuiTooltip-arrow': {
      color: '#f5f5f9',
      '&:before': {
        border: '1px solid #dadde9',
        borderBottomWidth: '2px',
        marginBottom: '2px', // Adjusted property name
      },
    },
  },
}));

const PasswordValidator: React.FC<PasswordValidatorProps> = ({
  isVisible,
  password,
}) => {
  const { t } = useTranslation('common');
  const [minChar, setMinChar] = useState(false);
  const [uppCase, setUppCase] = useState(false);
  const [lowCase, setLowCase] = useState(false);
  const [numChar, setNumChar] = useState(false);
  const [speChar, setSpeChar] = useState(false);

  useEffect(() => {
    const validateMinChar = async () => {
      try {
        await Yup.object()
          .shape({
            password: Yup.string().min(
              12,
              t('common.min12Chars', 'Minimum 12 characters'),
            ),
          })
          .validate({ password });
        setMinChar(true);
      } catch (error) {
        setMinChar(false);
      }
    };

    const validateUppCase = async () => {
      try {
        await Yup.object()
          .shape({
            password: Yup.string().matches(
              /[A-Z]/,
              t(
                'common.minOneUppercaseChar',
                'At least one uppercase character',
              ),
            ),
          })
          .validate({ password });
        setUppCase(true);
      } catch (error) {
        setUppCase(false);
      }
    };

    const validateLowerCase = async () => {
      try {
        await Yup.object()
          .shape({
            password: Yup.string().matches(
              /[a-z]/,
              t(
                'common.minOneLowercaseChar',
                'At least one lowercase character',
              ),
            ),
          })
          .validate({ password });
        setLowCase(true);
      } catch (error) {
        setLowCase(false);
      }
    };

    const validateNumChar = async () => {
      try {
        await Yup.object()
          .shape({
            password: Yup.string().matches(
              /\d/,
              t('common.minOneNumericChar', 'At least one numeric character'),
            ),
          })
          .validate({ password });
        setNumChar(true);
      } catch (error) {
        setNumChar(false);
      }
    };

    const validateSpeChar = async () => {
      try {
        await Yup.object()
          .shape({
            password: Yup.string().matches(
              /[!@#$%^&*()\-_+=[\]{}|/,.<>?~`:"';]/,
              t('common.minOneSpecialChar', 'At least one special character'),
            ),
          })
          .validate({ password });
        setSpeChar(true);
      } catch (error) {
        setSpeChar(false);
      }
    };

    // Reset all state variables to false if password is undefined or has length 0
    if (!password || password.length === 0) {
      setMinChar(false);
      setUppCase(false);
      setLowCase(false);
      setNumChar(false);
      setSpeChar(false);
    } else if (isVisible) {
      validateMinChar();
      validateUppCase();
      validateLowerCase();
      validateNumChar();
      validateSpeChar();
    }
  }, [isVisible, password]);

  return (
    <HtmlTooltip
      arrow
      placement="bottom-end"
      open={isVisible}
      title={
        <>
          <Typography variant="subtitle1" ml={2} mt={1} mb={-2}>
            {t('common.passwordMustInclude', 'Password must include:')}
          </Typography>
          <List dense>
            {/* minChar */}
            <ListItem
              style={{
                color: minChar ? 'green' : 'red',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {minChar ? (
                <DoneIcon sx={{ color: 'green', marginRight: '8px' }} />
              ) : (
                <ClearIcon sx={{ color: 'red', marginRight: '8px' }} />
              )}
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {t('common.min12Chars', 'Minimum 12 characters')}
                  </Typography>
                }
              />
            </ListItem>
            {/* uppCase */}
            <ListItem
              style={{
                color: uppCase ? 'green' : 'red',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {uppCase ? (
                <DoneIcon sx={{ color: 'green', marginRight: '8px' }} />
              ) : (
                <ClearIcon sx={{ color: 'red', marginRight: '8px' }} />
              )}
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {t(
                      'common.minOneUppercaseChar',
                      'At least one uppercase character',
                    )}
                  </Typography>
                }
              />
            </ListItem>
            {/* lowCase */}
            <ListItem
              style={{
                color: lowCase ? 'green' : 'red',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {lowCase ? (
                <DoneIcon sx={{ color: 'green', marginRight: '8px' }} />
              ) : (
                <ClearIcon sx={{ color: 'red', marginRight: '8px' }} />
              )}
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {t(
                      'common.minOneLowercaseChar',
                      'At least one lowercase character',
                    )}
                  </Typography>
                }
              />
            </ListItem>

            {/* numChar */}
            <ListItem
              style={{
                color: numChar ? 'green' : 'red',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {numChar ? (
                <DoneIcon sx={{ color: 'green', marginRight: '8px' }} />
              ) : (
                <ClearIcon sx={{ color: 'red', marginRight: '8px' }} />
              )}
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {t(
                      'common.minOneNumericChar',
                      'At least one numeric character',
                    )}
                  </Typography>
                }
              />
            </ListItem>

            {/* speChar */}
            <ListItem
              style={{
                color: speChar ? 'green' : 'red',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {speChar ? (
                <DoneIcon sx={{ color: 'green', marginRight: '8px' }} />
              ) : (
                <ClearIcon sx={{ color: 'red', marginRight: '8px' }} />
              )}
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {t(
                      'common.minOneSpecialChar',
                      'At least one special character',
                    )}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </>
      }
    >
      {/* Placeholder for valid children */}
      <div />
    </HtmlTooltip>
  );
};

export default PasswordValidator;
