import React, { PropsWithoutRef } from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ModelHeaderProps = {
  title: string | JSX.Element;
  handleClose: () => void;
};

const DefaultModelHeader = ({
  title,
  handleClose,
}: PropsWithoutRef<ModelHeaderProps>) => (
  <Grid container>
    <Grid item xs sx={{ display: 'flex', alignItems: 'center' }}>
      {typeof title === 'string' ? (
        <Typography variant="h6">{title}</Typography>
      ) : (
        title
      )}
    </Grid>
    <Grid item>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Grid>
  </Grid>
);

export default DefaultModelHeader;
