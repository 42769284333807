import React, { useRef, useCallback } from 'react';

import { SnackbarProvider, SnackbarKey } from 'notistack';
import { IconButton, styled } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';

interface IProps {
  children: React.ReactNode;
}

const TruncatedSnackbarProvider = styled(SnackbarProvider)({
  '.SnackbarItem-message': {
    maxHeight: '200px',
    overflow: 'hidden',
  },
});

const SnackbarProviderWithDismiss = ({ children }: IProps) => {
  const notistackRef = useRef<SnackbarProvider | null>(null);

  const DismissSnackbar = useCallback(
    (key: SnackbarKey) => (
      <IconButton onClick={() => notistackRef.current?.closeSnackbar(key)}>
        <HighlightOffIcon sx={{ color: 'white' }} />
      </IconButton>
    ),
    [],
  );

  return (
    <TruncatedSnackbarProvider
      iconVariant={{
        error: <WarningIcon sx={{ marginRight: '8px' }} />,
      }}
      action={DismissSnackbar}
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </TruncatedSnackbarProvider>
  );
};

export default SnackbarProviderWithDismiss;
