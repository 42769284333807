import emptySplitApi from 'services/api/ailyssaBaseApi';

import {
  CompaniesListApiResponse,
  CompaniesListApiArg,
  CompaniesCreateApiResponse,
  CompaniesCreateApiArg,
  CompaniesRetrieveApiResponse,
  CompaniesRetrieveApiArg,
  CompaniesUpdateApiResponse,
  CompaniesUpdateApiArg,
  CompaniesPartialUpdateApiResponse,
  CompaniesPartialUpdateApiArg,
  CompaniesUsersListApiArg,
  CompaniesUsersListApiResponse,
  CompaniesDestroyApiResponse,
  CompaniesDestroyApiArg,
  CompaniesRolesListApiArg,
  CompaniesRolesListApiResponse,
} from './types';

const companiesApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    companiesList: build.query<CompaniesListApiResponse, CompaniesListApiArg>({
      query: () => ({ url: '/companies/' }),
      providesTags: ['Company'],
    }),
    companiesCreate: build.mutation<
      CompaniesCreateApiResponse,
      CompaniesCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/companies/',
        method: 'POST',
        body: queryArg.company,
      }),
      invalidatesTags: ['Company', 'ResellerGroupCompany'],
    }),
    companiesRetrieve: build.query<
      CompaniesRetrieveApiResponse,
      CompaniesRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/companies/${queryArg.id}/` }),
      providesTags: (result) =>
        result ? [{ type: 'Company', id: result.id }] : ['Company'],
    }),
    companiesUpdate: build.mutation<
      CompaniesUpdateApiResponse,
      CompaniesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.company,
      }),
      invalidatesTags: ['Company', 'ResellerGroupCompany'],
    }),
    companiesPartialUpdate: build.mutation<
      CompaniesPartialUpdateApiResponse,
      CompaniesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedCompany,
      }),
      invalidatesTags: ['Company', 'ResellerGroupCompany'],
    }),
    companiesUsersList: build.query<
      CompaniesUsersListApiResponse,
      CompaniesUsersListApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.id}/users/`,
        params: {
          first_name:
            queryArg.firstName.length > 0 ? queryArg.firstName : undefined,
          last_name:
            queryArg.lastName.length > 0 ? queryArg.lastName : undefined,
          email: queryArg.email.length > 0 ? queryArg.email : undefined,
          username:
            queryArg.username.length > 0 ? queryArg.username : undefined,
          groups: queryArg.groups,
          sites: queryArg.sites,
        },
      }),
      providesTags: ['CompanyUser'],
    }),
    companiesRolesList: build.query<
      CompaniesRolesListApiResponse,
      CompaniesRolesListApiArg
    >({
      query: () => ({ url: '/companies/roles/' }),
    }),
    companiesDestroy: build.mutation<
      CompaniesDestroyApiResponse,
      CompaniesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company', 'ResellerGroupCompany'],
    }),
  }),
});

export const {
  useCompaniesListQuery,
  useCompaniesCreateMutation,
  useCompaniesRetrieveQuery,
  useCompaniesUpdateMutation,
  useCompaniesPartialUpdateMutation,
  useCompaniesUsersListQuery,
  useCompaniesDestroyMutation,
  useCompaniesRolesListQuery,
} = companiesApi;
export * from './types';
