import * as apiHooks from './ailyssaApi';

export const {
  useGetDocsSchemaOpenapiJsonQuery,
  useLivenessRetrieveQuery,
  useSitesSiteSiteGroupingsQuery,
  useS3SignedRetrieveQuery,
  useLazyS3SignedRetrieveQuery,
  useTelegramTestCreateMutation,
} = apiHooks;
