import React, { useEffect } from 'react';

import { Card, CardContent, Stack, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Controller, useWatch } from 'react-hook-form';

import { SettingsCardHeader } from 'components/common/styledComponents';
import {
  FormFieldDescription,
  SwitchFieldRenderer,
} from 'components/common/Forms';
import { ResourceFormProps } from 'types';
import { type User } from 'services/api/general';
import FilterCameras from 'components/common/FilterCameras';
import useConvertCamerasToSubCols from 'components/common/ColumnAndSubColumns/hooks/useConvertCamerasToSubCols';
import useSelectedItems from 'components/common/ColumnAndSubColumns/hooks/useSelectedItems';
import useFind from 'components/common/ColumnAndSubColumns/hooks/useFind';

interface IProps extends ResourceFormProps<User> {
  cameras: string[];
}

const UserNotificationSettings = ({ control, cameras }: IProps) => {
  const handleFindColAndScoWithSct = useFind();

  const { camerasData } = useConvertCamerasToSubCols();
  const { selectedItems, setSelectedItems } = useSelectedItems();

  const isNotiEnabled = useWatch({
    name: 'notification_settings.enabled',
    control,
  });

  useEffect(() => {
    const defaultSelectedItems = handleFindColAndScoWithSct(cameras);
    setSelectedItems(defaultSelectedItems);
  }, [cameras]);

  return (
    <Card>
      <SettingsCardHeader title="Notification Settings" />
      <CardContent>
        <SwitchFieldRenderer
          control={control}
          name="notification_settings.enabled"
          label="Allow Notifications"
        />
        {isNotiEnabled && (
          <Stack mt={2} spacing={1} pl={3}>
            <Stack spacing={2} direction="row">
              <SwitchFieldRenderer
                control={control}
                name="notification_settings.system_notifications"
                label="System Notifications"
              />
              <Tooltip title="You need to turn on browser notification in settings for system notification to work.">
                <InfoIcon sx={{ color: 'primary.main' }} />
              </Tooltip>
            </Stack>
            <Stack>
              <FormFieldDescription title="Select sites/cameras to receive notifications from" />
              <Controller
                control={control}
                name="notification_settings.cameras"
                render={({ field: { onChange } }) => (
                  <FilterCameras
                    selectedItems={selectedItems}
                    setSelectedItems={(items) => {
                      setSelectedItems(items);
                      onChange(items.subColTwo);
                    }}
                    columns={camerasData}
                  />
                )}
              />
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default UserNotificationSettings;
