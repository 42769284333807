import { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormProps,
  useForm,
} from 'react-hook-form';

import { BaseForm } from 'components/common/Forms/types';
import { type ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface UseFormHookProps<T extends FieldValues>
  extends Omit<UseFormProps<T>, 'resolver'> {
  handleUpdateFormState?: BaseForm['handleUpdateFormState'];
  schema?: ObjectSchema<any>;
}

const useFormHook = <T extends FieldValues>({
  handleUpdateFormState,
  schema,
  ...useFormProps
}: UseFormHookProps<T>) => {
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, ...methods } = useForm<T>({
    ...useFormProps,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: schema && yupResolver(schema),
  });

  const defaultInvalidHandler = () => {
    enqueueSnackbar('All fields must be valid', {
      variant: 'error',
    });
  };

  useEffect(() => {
    if (handleUpdateFormState) {
      handleUpdateFormState({ isDirty: methods.formState.isDirty });
    }
  }, [methods.formState.isDirty]);

  return {
    ...methods,
    handleSubmit: (
      onValid: SubmitHandler<T>,
      onInvalid?: SubmitErrorHandler<T>,
    ) => handleSubmit(onValid, onInvalid || defaultInvalidHandler),
  };
};

export default useFormHook;
