import React from 'react';

import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useConfirm } from 'contexts/ConfirmationDialogProvider';
import {
  type NotificationReadAllTabName,
  useUsersReadAllNotificationsCreateMutation,
  NotificationReadAllTypeName,
} from 'services/api/general';

interface IProps {
  selectedFilterTab: NotificationReadAllTabName;
  selectedFilterType: NotificationReadAllTypeName;
}

const MarkAllAsRead = ({ selectedFilterTab, selectedFilterType }: IProps) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [markAllAsRead] = useUsersReadAllNotificationsCreateMutation({});

  const confirm = useConfirm();

  const handleConfirm = () => {
    confirm({
      description: t(
        'user.confirmMarkAllAsRead',
        'Are you sure you want to mark all notifications as read?',
      ),
    }).then(() => {
      markAllAsRead({
        userNotificationsReadAllRequest: {
          tab_name: selectedFilterTab,
          type: selectedFilterType,
        },
      }).then(() =>
        enqueueSnackbar(
          t(
            'user.allNotificationsMarkedAsRead',
            'All notifications have been marked as read',
          ),
          {
            variant: 'success',
          },
        ),
      );
    });
  };

  return (
    <Tooltip title={t('user.markAllAsRead', 'Mark all as read')}>
      <IconButton onClick={handleConfirm}>
        <MarkEmailReadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default MarkAllAsRead;
