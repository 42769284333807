import { useEffect } from 'react';

import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import { useSocketContext } from 'socket';

const useTrackUserActivity = () => {
  const { data: curUser } = useUsersCurrentRetrieveQuery();

  const socketCtx = useSocketContext();

  const sendUserPingMessage = (activity: 'ACTIVE' | 'INACTIVE') => {
    socketCtx.emit('user_ping', {
      payload: activity,
    });
  };

  useEffect(() => {
    if (curUser) {
      sendUserPingMessage('ACTIVE');

      const onVisibilityChange = () => {
        if (document.hidden) {
          sendUserPingMessage('INACTIVE');
        } else {
          sendUserPingMessage('ACTIVE');
        }
      };

      document.addEventListener('visibilitychange', onVisibilityChange);

      return () =>
        document.removeEventListener('visibilitychange', onVisibilityChange);
    }
    return undefined;
  }, [curUser]);
};

export default useTrackUserActivity;
