import { useEffect } from 'react';

import { useSnackbar, VariantType } from 'notistack';
import { useLocation } from 'react-router';

const useRequestSystemNotificationPermission = () => {
  const { enqueueSnackbar } = useSnackbar();

  const requestSystemNotificationPermission = () => {
    Notification.requestPermission().then((res) => {
      let msg: string = '';
      let variant: VariantType | '' = '';
      switch (res) {
        case 'granted':
          msg = 'System notifications successfully enabled';
          variant = 'success';
          break;
        case 'denied':
          msg = 'System notifications permission was denied';
          variant = 'warning';
          break;
        case 'default':
          break;
        default:
          msg = 'Something went wrong';
          variant = 'error';
      }
      if (res !== 'default' && variant) {
        enqueueSnackbar(msg, {
          variant,
          preventDuplicate: true,
        });
      }
    });
  };

  const location = useLocation();
  useEffect(() => {
    if (
      Notification.permission === 'default' &&
      location.state &&
      location.state.from === 'login'
    ) {
      requestSystemNotificationPermission();
    }
  }, [location.pathname]);
};

export default useRequestSystemNotificationPermission;
