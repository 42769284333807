import React from 'react';

import { Box, Typography } from '@mui/material';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import { useTranslation } from 'react-i18next';

const NoAccess = () => {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        width: '75vw',
        height: '75vh',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
      }}
    >
      <NoEncryptionGmailerrorredIcon sx={{ fontSize: '10em' }} />

      <Typography variant="h2">
        {t('page.accessRestricted', 'Access to this page is restricted')}
      </Typography>
      <Typography variant="h5">
        {t(
          'page.noAccessContactAdmin',
          'Sorry but it looks like you don&apos;t have access to this page. Please contact your administrator for more information.',
        )}
      </Typography>
    </Box>
  );
};

export default NoAccess;
