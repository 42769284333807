import React from 'react';

import { Stack, Button } from '@mui/material';
import { ConfirmationDialogProps } from './types';

type IProps = Pick<
  ConfirmationDialogProps,
  'confirmationText' | 'onConfirm' | 'confirmText' | 'cancelText'
> & {
  confirmationInputVal: string;
  handleCancel: () => void;
};

const ConfirmationFooter = ({
  confirmationInputVal,
  confirmationText,
  onConfirm,
  confirmText,
  cancelText,
  handleCancel,
}: IProps) => (
  <Stack justifyContent="flex-end" direction="row" spacing={2}>
    <Button
      disabled={
        (confirmationText && confirmationText !== confirmationInputVal) || false
      }
      onClick={onConfirm}
      variant="contained"
      color="secondary"
      sx={{ minWidth: '8rem' }}
    >
      {confirmText || 'Yes'}
    </Button>
    <Button
      sx={{ minWidth: '8rem' }}
      onClick={handleCancel}
      variant="contained"
      color="info"
    >
      {cancelText || 'No'}
    </Button>
  </Stack>
);

export default ConfirmationFooter;
