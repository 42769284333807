import React from 'react';

import { Skeleton, Stack } from '@mui/material';

interface IProps {
  count?: number;
}

const NotificationsListSkeleton = ({ count = 8 }: IProps) => (
  <Stack
    sx={{
      height: '100%',
      width: '100%',
    }}
    spacing={1}
  >
    {Array.from({ length: count }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Skeleton variant="rectangular" height="8rem" width="100%" key={index} />
    ))}
  </Stack>
);

export default NotificationsListSkeleton;
