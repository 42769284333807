import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type RootState } from 'store';

export type Assignees = {
  assignees: number[];
};

const INITIAL_STATE: Assignees = {
  assignees: [],
};

const assigneesFilterSlice = createSlice({
  name: 'assigneesFilter',
  initialState: INITIAL_STATE,
  reducers: {
    updateSelectedAssignees(state, action: PayloadAction<number[]>) {
      state.assignees = action.payload;
    },
  },
});

export const getSelectedAssignees = (state: RootState) =>
  state.assigneeFilter.assignees;

export const { updateSelectedAssignees } = assigneesFilterSlice.actions;

export default assigneesFilterSlice.reducer;
