import React, { ComponentProps } from 'react';

import { Stack, Typography } from '@mui/material';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface ErrorMessageProps extends ComponentProps<typeof FormErrorMessage> {
  name: string;
  errors: Record<string, any>;
}

const ErrorMessage = ({ errors, name, ...props }: ErrorMessageProps) => {
  return errors && Array.isArray(errors[name]) ? (
    <FormErrorMessage
      name={name}
      errors={errors}
      as="p"
      render={() => (
        <Stack
          alignItems="center"
          mt={0.5}
          direction="row"
          spacing={0.5}
          sx={{ color: 'red' }}
        >
          <ReportProblemIcon sx={{ fontSize: '16px' }} />
          <Stack spacing={1}>
            {errors[name].map(({ message }: { message: string }) => {
              return (
                <Typography component="p" variant="subtitle2" key={message}>
                  {message}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
      )}
    />
  ) : (
    <FormErrorMessage
      render={({ message }) => (
        <Stack
          alignItems="center"
          mt={0.5}
          direction="row"
          spacing={0.5}
          sx={{ color: 'red' }}
        >
          <Stack spacing={1}>
            {message &&
              message.split('<br>').map((msg) => (
                <Stack
                  direction="row"
                  key={msg}
                  spacing={1}
                  alignItems="center"
                >
                  <ReportProblemIcon sx={{ fontSize: '16px' }} />
                  <Typography component="p" variant="subtitle2">
                    {msg}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      )}
      as="p"
      name={name}
      errors={errors}
      {...props}
    />
  );
};
export default ErrorMessage;
