import React from 'react';

import { Box, Typography, Divider, Stack } from '@mui/material';

const ColumnHeader = ({
  children,
  action,
}: {
  children: React.ReactNode;
  action?: React.ReactNode;
}) => (
  <Box sx={{ background: 'white', zIndex: '1' }}>
    <Stack
      direction="row"
      justifyContent="space-between"
      height="48px"
      alignItems="center"
    >
      <Typography sx={{ p: '4px 10px', fontSize: '20px', textAlign: 'center' }}>
        {children}
      </Typography>
      {action && action}
    </Stack>
    <Divider />
  </Box>
);

export default ColumnHeader;
