import useTrackUserActivity from 'hooks/useTrackUserActivity';
import { useEffect } from 'react';

import { useLocation } from 'react-router';
import i18n from 'i18n';

import { useUsersCurrentRetrieveQuery } from 'services/api/general';

const InitialPageLoad = () => {
  useTrackUserActivity();
  const { pathname } = useLocation();
  const { data } = useUsersCurrentRetrieveQuery();

  useEffect(() => {
    if (data) {
      i18n.changeLanguage(data.preferred_language);
    }
  }, [data?.preferred_language]);

  useEffect(() => {
    // Disable Ctrl + A that selects all the text

    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        if (document.activeElement?.tagName === 'INPUT') return;

        event.preventDefault(); // Prevent the default Ctrl + A behavior
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    // Ever time route changes, scroll back to top
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
export default InitialPageLoad;
