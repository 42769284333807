import React from 'react';

import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

interface IProps {
  handleSelect: () => void;
  handleMouseEnter: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: string,
  ) => void;
  checked: boolean;
  id: string;
  name: string;
  isActive?: boolean;
  indeterminate?: boolean;
  shouldBeDisabled?: boolean;
  handleClickText?: () => void;
  disableSelection?: boolean;
  renderPrimary?: (id: string, text: string) => React.ReactNode;
}

const Item = ({
  handleSelect,
  handleMouseEnter,
  checked,
  id,
  name,
  isActive,
  indeterminate,
  shouldBeDisabled,
  handleClickText,
  disableSelection,
  renderPrimary,
}: IProps) => (
  <ListItem
    key={id}
    onMouseEnter={(event) => handleMouseEnter(event, id)}
    disablePadding
    sx={{
      ' & .MuiListItemButton-root': {
        padding: '0',
        pl: '10px',
      },
    }}
  >
    <ListItemButton
      disableGutters
      selected={isActive}
      disableRipple
      onClick={handleSelect}
      sx={{
        pointerEvents: disableSelection || shouldBeDisabled ? 'none' : 'auto',
      }}
    >
      <ListItemIcon sx={{ minHeight: '30px', minWidth: '30px' }}>
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          size="small"
          disableRipple
          sx={{ pl: 0 }}
          disabled={shouldBeDisabled || disableSelection}
        />
      </ListItemIcon>
      <ListItemText
        disableTypography
        sx={{ fontSize: '12px', overflowWrap: 'break-word' }}
        primary={
          renderPrimary ? (
            renderPrimary(id, name)
          ) : (
            <Typography variant="subtitle2">{name}</Typography>
          )
        }
        onClick={() => handleClickText && handleClickText()}
      />
    </ListItemButton>
  </ListItem>
);

export default Item;
