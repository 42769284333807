import React from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  handleClose: () => void;
  title: string;
}

const DialogHeader = ({ handleClose, title }: IProps) => (
  <Stack alignItems="center" direction="row" justifyContent="space-between">
    <Typography variant="h6">{title}</Typography>
    <IconButton onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  </Stack>
);

export default DialogHeader;
