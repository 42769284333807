import React from 'react';
import { Avatar } from '@mui/material';

interface IProps {
  username?: string;
  avatar?: string | undefined;
}

const ProfileAvatar = ({ avatar, username }: IProps) => (
  <Avatar alt={username || 'User'} src={avatar} sx={{ width: 42, height: 42 }}>
    {username && username[0]}
  </Avatar>
);

export default ProfileAvatar;
