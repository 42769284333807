import moment from 'moment';

const getFormattedDate = (date: Date, format: string = 'hh:mm A, DD/MM/YYYY') =>
  moment(date).format(format);

export const getEntireDay = (dateOrString: Date | string) => {
  let date: Date;
  if (typeof dateOrString === 'string') date = new Date(dateOrString);
  else date = dateOrString;

  return new Date(date.getTime() + 86399000);
};

export default getFormattedDate;
