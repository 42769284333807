import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { RootState } from 'store';
import { type FootfallCategoriesFilter } from '../footfallApi/types';

interface FootfallFilterState {
  selectedCategory: FootfallCategoriesFilter;
  selectedLabels: string[];
}

const initialState: FootfallFilterState = {
  selectedCategory: 'all',
  selectedLabels: [],
};

export const footfallDashboardFiltersSlice = createSlice({
  name: 'footfallFilters',
  initialState,
  reducers: {
    updateCategory: (
      state,
      action: PayloadAction<FootfallCategoriesFilter>,
    ) => {
      state.selectedCategory = action.payload;
    },
    updateSelectedLabels: (state, action: PayloadAction<string[]>) => {
      state.selectedLabels = action.payload;
    },
  },
});

export const { updateCategory, updateSelectedLabels } =
  footfallDashboardFiltersSlice.actions;

export default footfallDashboardFiltersSlice.reducer;

export const useGetFootfallFilters = () =>
  useSelector((state: RootState) => state.footfallFilter);

export const useUpdateFootfallFilters = () => {
  const dispatch = useDispatch();

  const handleUpdateCategory = (newCategory: FootfallCategoriesFilter) => {
    dispatch(updateCategory(newCategory));
  };

  const handleUpdateSelectedLabels = (newLabels: string[]) => {
    dispatch(updateSelectedLabels(newLabels));
  };

  return {
    handleUpdateCategory,
    handleUpdateSelectedLabels,
  };
};
