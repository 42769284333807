type UserName = {
  first_name: string;
  last_name?: string | null;
};

const getFullName = ({ first_name, last_name }: UserName) => {
  if (!last_name) {
    return first_name;
  }
  return `${first_name} ${last_name}`;
};

export default getFullName;
