import React from 'react';

import { Menu, type MenuProps } from 'react-contexify';
import 'react-contexify/ReactContexify.css';

const CustomContextMenu = ({ style, ...props }: MenuProps) => (
  <Menu
    {...props}
    style={{
      minWidth: '12rem',
      ...style,
    }}
  />
);

export default CustomContextMenu;
