import React, {
  PropsWithChildren,
  cloneElement,
  ReactElement,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
} from '@mui/material';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

import { useCustomMediaQuery, useOpen } from 'hooks';
import { useConfirm } from 'contexts/ConfirmationDialogProvider';
import DefaultModelHeader from '../CustomDialog/ModelHeaders/DefaultModelHeader';
import ModelFooter from '../CustomDialog/ModelFooter';
import ModelHeaderWithSubmit from '../CustomDialog/ModelHeaders/ModelHeaderWithSubmit';
import { ModelAttrs } from './useCustomModelRef';

export type CustomModelProps = {
  title: string | JSX.Element;
  cancelButtonText?: string;
  triggerButton: ReactElement;
  confirmButton?: ReactElement;
  maxWidth: DialogProps['maxWidth'];
  noFooter?: boolean;
  noHeader?: boolean;
  dialogProps?: Omit<DialogProps, 'open'>;
  dialogTitleProps?: DialogTitleProps;
  dialogContentProps?: DialogContentProps;
  headerStyle?: 'default' | 'with-submit';
  renderHeader?: (close: () => void) => JSX.Element;
  actionBeforeClose?: (close: () => void) => void;
  triggerKeys?: string[];
  customModelInstance?: React.RefObject<ModelAttrs>;
};

const CustomModel = ({
  children,
  title,
  cancelButtonText = i18n.t('common.cancel', 'Cancel', { ns: 'common' }),
  triggerButton,
  confirmButton,
  maxWidth,
  noFooter,
  noHeader,
  dialogProps,
  dialogTitleProps,
  dialogContentProps,
  headerStyle = 'default',
  renderHeader,
  actionBeforeClose,
  triggerKeys,
  customModelInstance,
}: PropsWithChildren<CustomModelProps>) => {
  const { t } = useTranslation('common');
  const [isOpen, open, close] = useOpen();

  const fullScreen = useCustomMediaQuery('sm');
  const [pendingChanges, setPendingChanges] = useState();
  const confirm = useConfirm();

  const trigger = cloneElement(triggerButton, { onClick: open });
  const confirmButtonWithDisabled =
    confirmButton &&
    cloneElement(confirmButton, {
      disabled: pendingChanges === undefined ? false : !pendingChanges,
    });

  const handleCloseDialog = () => {
    if (actionBeforeClose) {
      actionBeforeClose(close);
      return;
    }

    if (!pendingChanges) {
      close();
      return;
    }

    confirm({
      description: t(
        'common.youHavePendingChanges',
        'You have pending changes',
      ),
      title: t('common.pendingChanges', 'Pending Changes'),
      confirmText: t('common.discardChanges', 'Discard Changes'),
      cancelText: t('common.continueEditing', 'Continue Editing'),
    }).then(() => close());
  };

  const triggerModelOnKeyPress = (e: KeyboardEvent) => {
    if (triggerKeys!.includes(e.key)) open();
  };

  useEffect(() => {
    if (!triggerKeys?.length) return undefined;

    document.addEventListener('keydown', triggerModelOnKeyPress);

    return () =>
      document.removeEventListener('keydown', triggerModelOnKeyPress);
  }, []);

  useImperativeHandle(
    customModelInstance,
    () => ({
      open,
      isOpen,
      close,
    }),
    [open, isOpen, close],
  );

  return (
    <>
      {trigger}
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        maxWidth={maxWidth}
        onClose={handleCloseDialog}
        fullWidth
        {...dialogProps}
      >
        {!noHeader && (
          <DialogTitle {...dialogTitleProps}>
            {renderHeader ? (
              renderHeader(close)
            ) : (
              <>
                {headerStyle === 'default' && (
                  <DefaultModelHeader
                    title={title}
                    handleClose={handleCloseDialog}
                  />
                )}
                {headerStyle === 'with-submit' && (
                  <ModelHeaderWithSubmit
                    title={title}
                    handleClose={handleCloseDialog}
                    cancelButtonText={cancelButtonText}
                    confirmButton={confirmButtonWithDisabled}
                  />
                )}
              </>
            )}
          </DialogTitle>
        )}
        <DialogContent {...dialogContentProps}>
          {React.Children.map(children, (child) =>
            cloneElement(child as ReactElement, { setPendingChanges }),
          )}
        </DialogContent>
        {!noFooter && (
          <DialogActions>
            <ModelFooter
              confirmButton={confirmButtonWithDisabled}
              cancelButtonText={cancelButtonText}
              handleClickCancel={handleCloseDialog}
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default CustomModel;
