import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import i18n from 'i18n';

import ColumnList from './components/ColumnList/ColumnList';
import SubColOneList from './components/SubColOneList/SubColOneList';
import SubColTwoList from './components/SubColTwoList/SubColTwoList';
import type {
  Column,
  Columns,
  HandleSelectColumn,
  HandleSelectSbuColOne,
  RenderPrimary,
  SelectedItems,
  SubColOne,
  SubColTwo,
} from './types';

export interface ColumnAndSubColumnProps {
  columns: Columns;
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
  handleSelectColumn?: HandleSelectColumn;
  handleSelectSubColOne?: HandleSelectSbuColOne;
  handleSelectSubColTwo?: (
    subColTwo: SubColTwo,
    selectedSubColOne: SubColOne,
    selectedColumn: Column,
  ) => void;
  threeColumn?: boolean;
  columnHeader?: string;
  subColOneHeader?: string;
  subColTwoHeader?: string;
  disableColumnSelection?: boolean;
  disableSubColOneSelection?: boolean;
  disableSubColTwoSelection?: boolean;
  renderSubColOnePrimary?: RenderPrimary;
  viewOnly?: boolean;
  selectAllColumn?: boolean;
}

const ColumnAndSubColumns = ({
  columns,
  selectedItems,
  setSelectedItems,
  handleSelectColumn,
  handleSelectSubColOne,
  disableColumnSelection,
  disableSubColOneSelection,
  handleSelectSubColTwo,
  columnHeader = i18n.t('sites', 'Sites', { ns: 'common' }),
  subColOneHeader = i18n.t('groups', 'Groups', { ns: 'common' }),
  subColTwoHeader = i18n.t('cameras', 'Cameras', { ns: 'common' }),
  threeColumn = true,
  renderSubColOnePrimary,
  viewOnly,
  selectAllColumn = true,
  disableSubColTwoSelection,
}: ColumnAndSubColumnProps) => {
  const [selectedColumn, setSelectedColumn] = useState<Column>();
  const [selectedSubColOne, setSelectedSubColOne] = useState<SubColOne>();

  useEffect(() => {
    setSelectedSubColOne(undefined);
  }, [selectedColumn]);

  return (
    <Grid height="100%" container>
      <Grid sx={{ minWidth: '0' }} height="100%" xs item>
        <ColumnList
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setSelectedColumn={setSelectedColumn}
          columns={columns}
          selectedColumn={selectedColumn}
          handleSelectColumn={handleSelectColumn}
          threeColumn={threeColumn}
          columnHeader={columnHeader}
          disableColumnSelection={disableColumnSelection || viewOnly}
          selectAllColumn={selectAllColumn}
        />
      </Grid>
      <Grid sx={{ minWidth: '0' }} height="100%" xs item>
        <SubColOneList
          setSelectedSubColOne={setSelectedSubColOne}
          selectedColumn={selectedColumn}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          handleSelectSubColOne={handleSelectSubColOne}
          threeColumn={threeColumn}
          subColOneHeader={subColOneHeader}
          selectedSubColOne={selectedSubColOne}
          disableSubColOneSelection={disableSubColOneSelection || viewOnly}
          renderSubColOnePrimary={renderSubColOnePrimary}
        />
      </Grid>
      {threeColumn && (
        <Grid height="100%" sx={{ minWidth: '0' }} xs item>
          <SubColTwoList
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            selectedColumn={selectedColumn}
            selectedSubColOne={selectedSubColOne}
            subColTwoHeader={subColTwoHeader}
            subColOneHeader={subColOneHeader}
            handleSelectSubColTwo={handleSelectSubColTwo}
            disableSubColTwoSelection={viewOnly || disableSubColTwoSelection}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ColumnAndSubColumns;
