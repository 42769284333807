import React from 'react';

import UserForm, { UserFormProps } from '../UserForm';
import useUpdateUserPermission from './useUpdateUserPermission';

interface IProps extends UserFormProps {
  userId: number;
  hidePermissionSection?: boolean;
}

const UpdateUserForm = ({
  userId,
  permissionSectionViewOnly,
  hidePermissionSection,
  ...userFormProps
}: IProps) => {
  const { canUpdateUsers, canUpdateOwnUser } = useUpdateUserPermission({
    userId,
  });

  return (
    <UserForm
      viewOnly={!canUpdateUsers && !canUpdateOwnUser}
      userId={userId}
      permissionSectionViewOnly={canUpdateOwnUser && !canUpdateUsers}
      hidePermissionSection={hidePermissionSection}
      {...userFormProps}
    />
  );
};

export default UpdateUserForm;
