import type { Column, SelectedItems } from '../../types';

interface ToggleColumnArgs {
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
}

const useToggleColumn = ({
  selectedItems,
  setSelectedItems,
}: ToggleColumnArgs) => {
  const handleSelectColumn = (column: Column) => {
    const selectedSubColOne = column.subColOne.reduce((acc, subColOne) => {
      acc.push(subColOne.id);
      return acc;
    }, [] as string[]);

    const selectedSubColTwo = column.subColOne
      .map((sco) => sco.subColTwo?.map(({ id }) => id) || [])
      .flat();

    setSelectedItems({
      column: [...selectedItems.column, column.id],
      subColOne: [...selectedItems.subColOne, ...selectedSubColOne],
      subColTwo: [...selectedItems.subColTwo, ...selectedSubColTwo],
    });
  };

  const handleDeselectColumn = (column: Column) => {
    const allSubColOne = column.subColOne.map((sco) => sco.id);

    const selectedScoIds = selectedItems.subColOne.filter(
      (sco) => !allSubColOne.includes(sco),
    );

    const sctIds = column.subColOne
      .map((sco) => sco.subColTwo?.map((sct) => sct.id))
      .flat();

    const selectedSctIds = selectedItems.subColTwo.filter(
      (sct) => !sctIds.includes(sct),
    );

    setSelectedItems({
      column: selectedItems.column.filter((col) => col !== column.id),
      subColOne: selectedScoIds,
      subColTwo: selectedSctIds,
    });
  };

  const handleToggleColumn = (column: Column) => {
    const hasBeenSelected = selectedItems.column.includes(column.id);

    if (hasBeenSelected) handleDeselectColumn(column);
    else handleSelectColumn(column);
  };

  return handleToggleColumn;
};

export default useToggleColumn;
