import { useLocation } from 'react-router';

const useGetCurrentSection = (context: 'section' | 'page') => {
  const location = useLocation();
  const idx = context === 'section' ? 0 : 1;

  return location.pathname.split('/').filter((v) => v)[idx];
};

export default useGetCurrentSection;
