const FrPermissions = {
  // VIEW
  VIEW_FR_TAB: 'PRV01',
  VIEW_FR_DASHBOARD: 'PRDA1',
  VIEW_FR_TRAIL: 'PRTR1',
  VIEW_FR_USER_MANAGEMENT: 'PRUM1',
  VIEW_FR_SETTINGS: 'PRSE1',
  VIEW_FR_ZONES: 'PRZO1',
  VIEW_FR_CONFIG: 'PRCO1',
  VIEW_FR_LIST_ZONES: 'PRZO2',
  VIEW_FR_PEOPLE: 'PRPE1',
  VIEW_FR_AUTHORIZATIONS: 'PRPE2',
  VIEW_FR_CONFIGURATIONS: 'PRUV1',
  VIEW_FR_CONFIGURATION: 'PRAV1',

  //   WRITE
  ADD_FR_PEOPLE: 'PRWA1',
  ADD_FR_MUGSHOTS: 'PRWM1',
  SET_FR_PRIMARY_MUGSHOT: 'PRWP1',
  ADD_FR_AUTHORIZATION: 'PRWA2',
  CONFIGURE_FR: 'PRWC1',
  ADD_FR_ZONE: 'PRWZ1',

  //   UPDATE
  UPDATE_FR_PEOPLE: 'PRUE1',
  UPDATE_FR_ZONE: 'PRUZ1',
  UPDATE_FR_RECORD: 'PRUR1',

  //   DELETE
  DELETE_FR_MUGSHOTS: 'PRWD1',
  DELETE_FR_PEOPLE: 'PRDE1',
  DELETE_FR_RECORD: 'PRDR1',
  DELETE_FR_ZONE: 'PRDZ1',
  DELETE_FR_AUTHORIZATION: 'PRDA2',
};

export default FrPermissions;
