import React, {
  DependencyList,
  createContext,
  useContext,
  useEffect,
} from 'react';

import { type ThrottleSettings, throttle } from 'lodash';
import { io } from 'socket.io-client';

export const socket = io(import.meta.env.VITE_SOCKET_CONNECTION_URL, {
  extraHeaders: {
    X_ACCESS_KEY: localStorage.getItem('token') || '',
    X_REFRESH_KEY: localStorage.getItem('refresh') || '',
  },
});

const SocketContext = createContext(socket);

const SocketContextProvider = ({ children }: { children: React.ReactNode }) => (
  <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
);

export const useSocketContext = () => useContext(SocketContext);

export type WebsocketMessageEnum =
  | 'new_alert'
  | 'new_notification'
  | 'configured_area_update'
  | 'new_person_record'
  | 'new_vehicle_record'
  | 'new_inspection_record'
  | 'new_vehicle_management_record'
  | undefined;

interface UseThrottledRefetchListenerProps {
  message: WebsocketMessageEnum;
  refetch: (eventData: any) => void;
  throttledMS?: number;
  throttleSettings?: ThrottleSettings;
  deps?: DependencyList;
  stopListening?: boolean;
}

export const useThrottledRefetchListener = ({
  message,
  refetch,
  throttledMS = 5000,
  throttleSettings = {
    trailing: true,
  },
  deps,
  stopListening,
}: UseThrottledRefetchListenerProps) => {
  const socketCtx = useSocketContext();

  // NOTE: consistent-return can be satisfied with the following:
  // `return () => {}` since the return value is void
  // so we disable it anyway
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const throttledRefetch = throttle(refetch, throttledMS, throttleSettings);

    const handleStopListening = () => {
      socketCtx.off(message, throttledRefetch);
    };

    if (message && !stopListening) {
      socketCtx.on(message, throttledRefetch);
    }

    if (stopListening) handleStopListening();

    return () => {
      handleStopListening();
    };
  }, [message, socketCtx, ...(deps || []), stopListening]);
};

export default SocketContextProvider;
