import React, { PropsWithoutRef, useCallback } from 'react';

import { Box, Button, Card, CardContent, Grid, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSnackbar } from 'notistack';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomDialog from 'components/common/CustomDialog';
import { BaseDialogForm } from 'components/common/Forms/types';
import FieldSet from 'components/common/Forms/FieldSet';
import getFullName from 'utils/user/getFullName';
import {
  useUsersRetrieveQuery,
  type User,
  useUsersCurrentRetrieveQuery,
} from 'services/api/general';
import FormDialog from 'components/common/FormDialog';
import { SettingsCardHeader } from 'components/common/styledComponents';
import ProvisionOTP from 'components/common/ProvisionOTP/ProvisionOTP';
import NewBtnWithLoading from 'components/common/NewBtnWithLoading';
import AccountStatusBadge from 'components/common/AccountStatusBadge';
import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import UserFormBasicConfigSection from './Sections/Basic/BasicConfig';
import UserFormPermsConfigSection from './Sections/Permissions/PermissionsConfig';
import useHandleUserFormData from './useHandleUserFormData';
import useUserValidationSchema from './useUserValidationSchema';
import userFormDefaultVals from './constants';
import useHandleResendButton from './useHandleResendButton';
import UserNotificationSettings from './Sections/UserNotificationSettings';

export interface UserFormProps extends BaseDialogForm {
  userId?: number;
  permissionSectionViewOnly?: boolean;
  hidePermissionSection?: boolean;
  viewOnly?: boolean;
}

const UserForm = ({
  userId,
  permissionSectionViewOnly,
  hidePermissionSection = false,
  viewOnly,
  ...dialogProps
}: PropsWithoutRef<UserFormProps>) => {
  const { t } = useTranslation('common');
  const userValidationSchema = useUserValidationSchema({
    hidePermissionSection,
  });
  const { enqueueSnackbar } = useSnackbar();

  const { data: user } = useUsersRetrieveQuery(
    userId ? { id: userId } : skipToken,
  );

  const { data } = useUsersCurrentRetrieveQuery();

  const isCurUser = data?.id === userId;

  const handleSubmit = useHandleUserFormData({
    action: userId ? 'update' : 'create',
  });

  const { handleClick: handleResendButtonClick, isLoading: resendIsLoading } =
    useHandleResendButton();

  const platformConfig = usePlatformConfigContext();
  const resendLoadingColor = platformConfig.theme_palette?.primary?.main
    ? platformConfig.theme_palette.primary.main
    : 'black';

  const resendButton = useCallback(
    (getValues: UseFormGetValues<User>) => {
      return (
        <NewBtnWithLoading
          onClick={async () => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const [first_name, password] = getValues([
              'first_name',
              'password',
            ]);

            try {
              if (userId) {
                await handleResendButtonClick(
                  userId,
                  {
                    password,
                  },
                  first_name,
                );
              }
            } catch (e) {
              enqueueSnackbar(`${e}`, { variant: 'error' });
            }
          }}
          type="submit"
          disabled={resendIsLoading}
          isLoading={resendIsLoading}
          circularProgressProps={{ sx: { color: resendLoadingColor } }}
          size="small"
          variant="outlined"
          sx={{ width: '150px' }}
        >
          RESEND INVITATION
        </NewBtnWithLoading>
      );
    },
    [resendIsLoading],
  );

  return (
    <FormDialog
      useFormProps={{
        schema: userValidationSchema,
        defaultValues: user || (userFormDefaultVals as User),
      }}
      title={
        userId ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <Box>{`${t('settings.capsEdit', 'Edit')} ${
              user ? getFullName(user) : ''
            }`}</Box>
            {user && <AccountStatusBadge status={user.account_state} />}
          </Stack>
        ) : (
          t('settings.createNewUser', 'Create New User')
        )
      }
      formId="user-form"
      onSubmit={handleSubmit}
      closeFormAfterSubmit={!userId}
      viewOnly={viewOnly}
      secondaryActionButtonRender={(getValues) => {
        return user?.account_state === 'invited'
          ? resendButton(getValues)
          : null;
      }}
      {...dialogProps}
    >
      {({ control, setValue, watch }) => (
        <Grid container spacing={2} flexDirection="column">
          <Grid item lg={12}>
            <Card>
              <SettingsCardHeader
                title={t('settings.basicConfig', 'Basic Configuration')}
              />
              <CardContent>
                <UserFormBasicConfigSection
                  userId={userId}
                  control={control}
                  setValue={setValue}
                  userAccountState={user?.account_state}
                />
              </CardContent>
            </Card>
          </Grid>
          {!hidePermissionSection && (
            <Grid item lg={12}>
              <FieldSet viewOnly={permissionSectionViewOnly}>
                <Card>
                  <SettingsCardHeader
                    title={t('settings.permissions', 'Permissions')}
                  />
                  <CardContent>
                    <UserFormPermsConfigSection
                      control={control}
                      watch={watch}
                    />
                  </CardContent>
                </Card>
              </FieldSet>
            </Grid>
          )}
          {isCurUser && (
            <Grid item lg={12}>
              <FieldSet>
                <Card>
                  <SettingsCardHeader
                    title={t('settings.2faConfig', '2FA Configuration')}
                  />
                  <CardContent>
                    <CustomDialog
                      maxWidth={false}
                      triggerButton={
                        <Button variant="contained" color="primary">
                          {t('settings.provision2fa', 'Provision 2FA')}
                        </Button>
                      }
                      title="Provision 2FA"
                      dialogProps={{ maxWidth: 'md' }}
                    >
                      <ProvisionOTP />
                    </CustomDialog>
                  </CardContent>
                </Card>
              </FieldSet>
            </Grid>
          )}

          {isCurUser && (
            <Grid item xs>
              <UserNotificationSettings
                cameras={data?.notification_settings.cameras || []}
                control={control}
              />
            </Grid>
          )}
        </Grid>
      )}
    </FormDialog>
  );
};

export default UserForm;
