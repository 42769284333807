import emptySplitApi from 'services/api/ailyssaBaseApi';
// eslint-disable-next-line
import {
  CamerasListApiArg,
  CamerasCreateApiResponse,
  CamerasPaginatedListApiArg,
  CamerasPaginatedListApiResponse,
  CamerasCreateApiArg,
  CamerasRetrieveApiResponse,
  CamerasRetrieveApiArg,
  CamerasUpdateApiResponse,
  CamerasUpdateApiArg,
  CamerasPartialUpdateApiResponse,
  CamerasPartialUpdateApiArg,
  CamerasDestroyApiResponse,
  CamerasDestroyApiArg,
  CamerasCalibrateCreateApiResponse,
  CamerasCalibrateCreateApiArg,
  CamerasCalibratePullCreateApiResponse,
  CamerasCalibratePullCreateApiArg,
  CamerasCalibratePushCreateApiResponse,
  CamerasCalibratePushCreateApiArg,
  CamerasFrameUpdateCreateApiResponse,
  CamerasFrameUpdateCreateApiArg,
  CamerasListApiResponse,
  CamerasUploadCsvCreateApiArg,
  CamerasUploadCsvCreateApiResponse,
  CamerasLatestInspectionTimestampRetrieveApiArg,
  CamerasLatestInspectionTimestampRetrieveApiResponse,
  CamerasSudoRetrieveApiResponse,
  CamerasSudoRetrieveApiArg,
  CamerasSudoUpdateApiResponse,
  CamerasSudoUpdateApiArg,
  CamerasSudoDeleteCameraDestroyApiArg,
  CamerasBulkUpdatePartialUpdateApiArg,
  CamereasLabelsListResponse,
  CamerasLabelsListArgs,
  CamerasRefreshCameraHealthCreateApiResponse,
  CamerasRefreshCameraHealthCreateApiArg,
  CamerasSendTestAlertCreateApiResponse,
  CamerasSendTestAlertCreateApiArg,
  CamerasGroupBySitesListApiArg,
  CamerasGroupBySitesListApiResponse,
  CamerasAlertFeaturesUpdateApiArg,
  CamerasVehicleManagementConfigurationUpdateApiResponse,
  CamerasVehicleManagementConfigurationUpdateApiArg,
  CamerasInspectionConfigurationUpdateApiResponse,
  CamerasInspectionConfigurationUpdateApiArg,
  CamerasFootfallConfigurationUpdateApiResponse,
  CamerasFootfallConfigurationUpdateApiArg,
  CamerasWorkhourConfigurationUpdateApiResponse,
  CamerasWorkhourConfigurationUpdateApiArg,
} from 'services/api/general';

const camerasApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    camerasList: build.query<CamerasListApiResponse, CamerasListApiArg>({
      query: (queryArg) => ({
        url: '/cameras/',
        params: {
          cameras: queryArg.cameras,
          groups: queryArg.groups,
          zones: queryArg.zones,
          sites: queryArg.sites,
          page: queryArg.page,
        },
      }),
      providesTags: ['CameraBase'],
    }),
    camerasPaginatedList: build.query<
      CamerasPaginatedListApiResponse,
      CamerasPaginatedListApiArg
    >({
      query: (queryArg) => ({
        url: '/cameras/paginated/',
        params: {
          cameras: queryArg.cameras,
          groups: queryArg.groups,
          zones: queryArg.zones,
          sites: queryArg.sites,
          page: queryArg.page,
          size: queryArg.size,
          tab: queryArg.tab,
        },
      }),
      providesTags: ['CameraPaginated'],
    }),
    camerasCreate: build.mutation<
      CamerasCreateApiResponse,
      CamerasCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/cameras/',
        method: 'POST',
        body: queryArg.camera,
      }),
      invalidatesTags: ['CameraBase', 'Camera'],
    }),
    camerasRetrieve: build.query<
      CamerasRetrieveApiResponse,
      CamerasRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/cameras/${queryArg.id}/` }),
      providesTags: (result) =>
        result ? [{ type: 'Camera', id: result.id }] : ['Camera', 'CameraBase'],
      keepUnusedDataFor: 5,
    }),
    camerasUpdate: build.mutation<
      CamerasUpdateApiResponse,
      CamerasUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.camera,
      }),
      invalidatesTags: ['CameraBase', 'Camera', 'CameraPaginated'],
    }),
    camerasPartialUpdate: build.mutation<
      CamerasPartialUpdateApiResponse,
      CamerasPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedCamera,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'Camera', id: result.id }] : ['Camera'],
    }),
    camerasDestroy: build.mutation<
      CamerasDestroyApiResponse,
      CamerasDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/${queryArg.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Camera', 'CameraBase'],
    }),
    camerasCalibrateCreate: build.mutation<
      CamerasCalibrateCreateApiResponse,
      CamerasCalibrateCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/cameras/calibrate/',
        method: 'POST',
        body: queryArg.alertCalibrationRequest,
      }),
    }),
    camerasCalibratePullCreate: build.mutation<
      CamerasCalibratePullCreateApiResponse,
      CamerasCalibratePullCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/cameras/calibrate/pull',
        method: 'POST',
        body: queryArg.pullCalibrationRequest,
      }),
      invalidatesTags: ['Camera'],
    }),
    camerasCalibratePushCreate: build.mutation<
      CamerasCalibratePushCreateApiResponse,
      CamerasCalibratePushCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/cameras/calibrate/push',
        method: 'POST',
        body: queryArg.pushCalibrationRequest,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Camera', id: arg.pushCalibrationRequest.target_cam_id },
      ],
    }),
    camerasFrameUpdateCreate: build.mutation<
      CamerasFrameUpdateCreateApiResponse,
      CamerasFrameUpdateCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/cameras/frame/update',
        method: 'POST',
        body: queryArg.updateCameraLatestFrame,
      }),
    }),
    camerasUploadCsvCreate: build.mutation<
      CamerasUploadCsvCreateApiResponse,
      CamerasUploadCsvCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/upload_csv/`,
        method: 'POST',
        body: queryArg,
        formData: true,
      }),
      invalidatesTags: ['CameraBase'],
    }),
    camerasLatestInspectionTimestampRetrieve: build.query<
      CamerasLatestInspectionTimestampRetrieveApiResponse,
      CamerasLatestInspectionTimestampRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/${queryArg.id}/latest_inspection_timestamp/`,
      }),
    }),
    camerasSudoRetrieve: build.query<
      CamerasSudoRetrieveApiResponse,
      CamerasSudoRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/cameras/sudo/${queryArg.id}/` }),
      providesTags: ['CameraSudoSettings'],
    }),
    camerasSudoUpdate: build.mutation<
      CamerasSudoUpdateApiResponse,
      CamerasSudoUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/sudo/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.cameraSudoSettings,
      }),
      invalidatesTags: ['CameraSudoSettings'],
    }),
    camerasSudoDeleteCameraDestroy: build.mutation<
      void,
      CamerasSudoDeleteCameraDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/sudo/${queryArg.id}/delete_camera/`,
        method: 'DELETE',
      }),
    }),
    camerasBulkUpdatePartialUpdate: build.mutation<
      unknown,
      CamerasBulkUpdatePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/bulk_update/`,
        method: 'PATCH',
        body: queryArg.patchedBulkUpdateRequest,
      }),
      invalidatesTags: ['CameraBase'],
    }),
    camerasLabelsList: build.query<
      CamereasLabelsListResponse,
      CamerasLabelsListArgs
    >({
      query: (queryArg) => ({
        url: `/cameras/${queryArg.id}/labels/`,
        params: {
          usecase: queryArg.usecase,
        },
      }),
      providesTags: (result, error, queryArg) =>
        queryArg
          ? [{ type: 'CamerasLabelsList', id: queryArg.id }]
          : ['CamerasLabelsList'],
    }),
    camerasGroupBySitesList: build.query<
      CamerasGroupBySitesListApiResponse,
      CamerasGroupBySitesListApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/group_by_sites/`,
        params: {
          apn_id: queryArg.apnId,
          included_cameras: queryArg.includedCameras,
          last_alert_after: queryArg.lastAlertAfter,
          last_alert_before: queryArg.lastAlertBefore,
        },
      }),
    }),
    camerasRefreshCameraHealthCreate: build.mutation<
      CamerasRefreshCameraHealthCreateApiResponse,
      CamerasRefreshCameraHealthCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/refresh_camera_health/`,
        method: 'POST',
        body: queryArg.refreshCameraRequest,
      }),
    }),
    camerasSendTestAlertCreate: build.mutation<
      CamerasSendTestAlertCreateApiResponse,
      CamerasSendTestAlertCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/send_test_alert/`,
        method: 'POST',
        body: queryArg.refreshCameraRequest,
      }),
    }),
    camerasAlertFeaturesUpdate: build.mutation<
      unknown,
      CamerasAlertFeaturesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/alert_features_configuration/`,
        method: 'PUT',
        body: queryArg.alertFeaturesUpdateRequest,
      }),
      invalidatesTags: (result, error, arg) =>
        arg
          ? arg.alertFeaturesUpdateRequest.camera_ids.map((camId) => ({
              type: 'Camera',
              id: camId,
            }))
          : ['Camera'],
    }),
    camerasVehicleManagementConfigurationUpdate: build.mutation<
      CamerasVehicleManagementConfigurationUpdateApiResponse,
      CamerasVehicleManagementConfigurationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/vehicle_management_configuration/`,
        method: 'PUT',
        body: queryArg.updateVehicleManagementRequest,
      }),
      invalidatesTags: ['Camera'],
    }),
    camerasInspectionConfigurationUpdate: build.mutation<
      CamerasInspectionConfigurationUpdateApiResponse,
      CamerasInspectionConfigurationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/inspection_configuration/`,
        method: 'PUT',
        body: queryArg.updateCameraInspectionRequest,
      }),
      invalidatesTags: ['Camera'],
    }),
    camerasFootfallConfigurationUpdate: build.mutation<
      CamerasFootfallConfigurationUpdateApiResponse,
      CamerasFootfallConfigurationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/footfall_configuration/`,
        method: 'PUT',
        body: queryArg.updateCameraFootfallRequest,
      }),
      invalidatesTags: ['Camera'],
    }),
    camerasWorkhourConfigurationUpdate: build.mutation<
      CamerasWorkhourConfigurationUpdateApiResponse,
      CamerasWorkhourConfigurationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cameras/workhour_configuration/`,
        method: 'PUT',
        body: queryArg.updateCameraWorkHourRequest,
      }),
      invalidatesTags: ['Camera'],
    }),
  }),
});

export const {
  useCamerasListQuery,
  useCamerasPaginatedListQuery,
  useCamerasCreateMutation,
  useCamerasRetrieveQuery,
  useCamerasUpdateMutation,
  useCamerasPartialUpdateMutation,
  useCamerasDestroyMutation,
  useCamerasCalibrateCreateMutation,
  useCamerasCalibratePullCreateMutation,
  useCamerasCalibratePushCreateMutation,
  useCamerasFrameUpdateCreateMutation,
  useCamerasUploadCsvCreateMutation,
  useCamerasLatestInspectionTimestampRetrieveQuery,
  useCamerasSudoRetrieveQuery,
  useCamerasSudoUpdateMutation,
  useCamerasSudoDeleteCameraDestroyMutation,
  useCamerasBulkUpdatePartialUpdateMutation,
  useCamerasLabelsListQuery,
  useCamerasRefreshCameraHealthCreateMutation,
  useCamerasSendTestAlertCreateMutation,
  useCamerasGroupBySitesListQuery,
  useCamerasAlertFeaturesUpdateMutation,
  useCamerasVehicleManagementConfigurationUpdateMutation,
  useCamerasInspectionConfigurationUpdateMutation,
  useCamerasFootfallConfigurationUpdateMutation,
  useCamerasWorkhourConfigurationUpdateMutation,
} = camerasApi;

export * from './types';
