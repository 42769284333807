import React, { PropsWithChildren, ReactElement, cloneElement } from 'react';

import useHasPermission from './useHasPermission';
import FieldSet from '../Forms/FieldSet';

export interface WithPermissionsProps<TProps> {
  permission?: string | string[];
  superuser?: boolean;
  onPermissionFailed?: JSX.Element;
  failPermissionProps?: Partial<TProps>;
  manualAuthorisation?: {
    permission: string;
    isAuthorised: () => boolean;
  };
  viewOnlyOnFailed?: boolean;
}

const WithPermissions = <TProps,>({
  permission,
  superuser,
  children,
  onPermissionFailed,
  failPermissionProps,
  manualAuthorisation,
  viewOnlyOnFailed,
}: PropsWithChildren<WithPermissionsProps<TProps>>) => {
  const hasPermission = useHasPermission({
    permission,
    superuser,
    manualAuthorisation,
  });

  if (hasPermission) {
    // User has the permission
    return children as ReactElement;
  }

  if (viewOnlyOnFailed) {
    return <FieldSet viewOnly>{children}</FieldSet>;
  }

  // User has no permission
  if (failPermissionProps && !onPermissionFailed) {
    return cloneElement(children as ReactElement, failPermissionProps);
  }

  return onPermissionFailed || null;
};

export default WithPermissions;

interface WithReadWritePermsProps {
  read: string;
  write: string;
  superuser?: boolean;
  children: React.ReactNode;
  viewOnlyOnFailed?: boolean;
}

export const WithReadWritePerms = ({
  read,
  write,
  superuser,
  children,
  viewOnlyOnFailed = true,
}: WithReadWritePermsProps) => {
  const hasWritePerm = useHasPermission({ permission: write, superuser });
  const hasReadPerm = useHasPermission({ permission: read, superuser });

  if (hasWritePerm) {
    return children as ReactElement;
  }

  if (hasReadPerm)
    return <FieldSet viewOnly={viewOnlyOnFailed}>{children}</FieldSet>;

  return null;
};
