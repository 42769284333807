import React from 'react';

import { Stack, Typography } from '@mui/material';

const NoOptionMsg = ({ message }: { message: string }) => (
  <Stack sx={{ height: '80%', alignItems: 'center', justifyContent: 'center' }}>
    <Typography sx={{ color: 'gray', padding: '8px 16px', fontSize: '14px' }}>
      {message}
    </Typography>
  </Stack>
);

export default NoOptionMsg;
