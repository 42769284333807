import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import {
  ResellerDeployments,
  useResellerDeploymentsGetConfigRetrieveQuery,
} from 'services/api/general';

const PlatformConfigContext = createContext({} as ResellerDeployments);

interface IProps {
  children: React.ReactNode;
}

const PlatformConfigContextProvider = ({ children }: IProps) => {
  const { data: platformConfig, isLoading } =
    useResellerDeploymentsGetConfigRetrieveQuery();

  const memoizedPlatformConfig = useMemo(
    () => platformConfig,
    [platformConfig],
  );

  useEffect(() => {
    if (!platformConfig) return;

    (() => {
      // Change favicon
      const link = document.querySelector('link[rel="icon"]');
      if (link) {
        link.setAttribute(
          'href',
          platformConfig.favicon || platformConfig.logo_main,
        );
      }
    })();

    (() => {
      // Update document title
      document.title = platformConfig.title;
    })();
  }, [platformConfig]);

  if (isLoading)
    return (
      <Stack
        sx={{
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Stack>
    );

  if (!memoizedPlatformConfig)
    return (
      <Stack
        sx={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}
      >
        Development error
      </Stack>
    );

  return (
    <PlatformConfigContext.Provider value={memoizedPlatformConfig}>
      {children}
    </PlatformConfigContext.Provider>
  );
};

export const usePlatformConfigContext = () => useContext(PlatformConfigContext);

export default PlatformConfigContextProvider;
