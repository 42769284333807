import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { type DateRange } from '@mui/x-date-pickers-pro';
import { subWeeks } from 'date-fns';

import type { RootState } from 'store';
import { Authorization02BEnum } from 'tabs/vehicleAccess/api';

export type VaFiltersState = {
  vaPlateChars: string | undefined;
  vaVehicleTypes: string[];
  duration: {
    minimum: { hour: string; minute: string };
    maximum: { hour: string; minute: string };
  };
  vaEntryCameras: string[];
  vaExitCameras: string[];
  vaEntryDateRange: DateRange<Date>;
  vaExitDateRange: DateRange<Date>;
  selectedAuthorisations: Authorization02BEnum[] | undefined;
};

const initialState: VaFiltersState = {
  vaPlateChars: undefined,
  vaVehicleTypes: [],
  duration: {
    minimum: { hour: '', minute: '' },
    maximum: { hour: '', minute: '' },
  },
  vaEntryCameras: [],
  vaExitCameras: [],
  vaEntryDateRange: [subWeeks(new Date(), 2), new Date()],
  vaExitDateRange: [null, null],
  selectedAuthorisations: undefined,
};

interface UpdateDurationPayload {
  val: string;
  type: 'minimum' | 'maximum';
  time: 'hour' | 'minute';
}

export const vaFiltersSlice = createSlice({
  name: 'vaFilters',
  initialState,
  reducers: {
    setVaPlateChars: (state, action: PayloadAction<string | undefined>) => {
      state.vaPlateChars = action.payload || undefined;
    },
    setVaVehicleTypes: (state, action: PayloadAction<string[]>) => {
      state.vaVehicleTypes = action.payload;
    },
    // => Duration Filter methods Start
    updateDuration(
      { duration },
      { payload: { val, type, time } }: PayloadAction<UpdateDurationPayload>,
    ) {
      duration[type][time] = val;
    },
    resetDuration({ duration }) {
      duration.minimum = { minute: '', hour: '' };
      duration.maximum = { minute: '', hour: '' };
    },
    // => Duration Filter Methods End
    setVaEntryCameras: (state, action: PayloadAction<string[]>) => {
      state.vaEntryCameras = action.payload;
    },
    setVaExitCameras: (state, action: PayloadAction<string[]>) => {
      state.vaExitCameras = action.payload;
    },
    setVaEntryDateRange: (state, action: PayloadAction<DateRange<Date>>) => {
      state.vaEntryDateRange = action.payload;
    },
    setVaExitDateRange: (state, action: PayloadAction<DateRange<Date>>) => {
      state.vaExitDateRange = action.payload;
    },
    setSelectedAuthorisations: (
      state,
      action: PayloadAction<Authorization02BEnum[] | undefined>,
    ) => {
      state.selectedAuthorisations = action.payload;
    },
  },
});

export const {
  setVaPlateChars,
  setVaVehicleTypes,
  updateDuration,
  resetDuration,
  setVaEntryCameras,
  setVaExitCameras,
  setVaEntryDateRange,
  setVaExitDateRange,
  setSelectedAuthorisations,
} = vaFiltersSlice.actions;

export const useSetVaPlateChars = () => {
  const dispatch = useDispatch();
  return (c: string | undefined) => dispatch(setVaPlateChars(c));
};

export const useSetVaVehicleTypes = () => {
  const dispatch = useDispatch();
  return (c: string[]) => dispatch(setVaVehicleTypes(c));
};

export const useGetVaPlateChars = () =>
  useSelector((state: RootState) => state.vaFilters.vaPlateChars);

export const useGetVaVehicleTypes = () =>
  useSelector((state: RootState) => state.vaFilters.vaVehicleTypes);

export const useSetVaEntryCameras = () => {
  const dispatch = useDispatch();
  return (c: string[]) => dispatch(setVaEntryCameras(c));
};

export const useSetVaExitCameras = () => {
  const dispatch = useDispatch();
  return (c: string[]) => dispatch(setVaExitCameras(c));
};

export const useSetVaEntryDateRange = () => {
  const dispatch = useDispatch();
  return (r: DateRange<Date>) => dispatch(setVaEntryDateRange(r));
};

export const useSetVaExitDateRange = () => {
  const dispatch = useDispatch();
  return (r: DateRange<Date>) => dispatch(setVaExitDateRange(r));
};

export const useSetSelectedAuthorisations = () => {
  const dispatch = useDispatch();
  return (r: Authorization02BEnum[] | undefined) =>
    dispatch(setSelectedAuthorisations(r));
};

export const useGetVaEntryDateRange = () =>
  useSelector((state: RootState) => state.vaFilters.vaEntryDateRange);

export const useGetVaExitDateRange = () =>
  useSelector((state: RootState) => state.vaFilters.vaExitDateRange);

export const useGetSelectedAuthorisations = () =>
  useSelector((state: RootState) => state.vaFilters.selectedAuthorisations);

export default vaFiltersSlice.reducer;
