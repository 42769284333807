import { useDispatch } from 'react-redux';
import axios from 'axios';

import {
  injectedRtkApi,
  S3SignedRetrieveApiArg,
  S3SignedUploadUrl,
} from 'services/api/ailyssaApi';
import type { AppDispatch } from 'store/store';

const useGetS3Url = () => {
  const dispatch: AppDispatch = useDispatch();

  return (filename: string, context: S3SignedRetrieveApiArg['context']) => {
    const action = injectedRtkApi.endpoints.s3SignedRetrieve.initiate({
      filename,
      context,
    });

    return new Promise<S3SignedUploadUrl>((resolve, reject) => {
      dispatch(action)
        .unwrap()
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  };
};

const useHandleUploadFile = () => {
  const headers = { 'Content-Type': 'application/octet-stream' };

  return (file: File, url: string) =>
    new Promise((resolve, reject) => {
      axios
        .put(url, file, { headers })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
};

export { useGetS3Url, useHandleUploadFile };
