import React from 'react';

import { Checkbox } from '@mui/material';

import { Column, SelectedItems } from '../../types';

interface IProps {
  columns: Column[];
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
  disableColumnSelection?: boolean;
  threeColumn: boolean;
}

const ColumnSelectAll = ({
  columns,
  setSelectedItems,
  selectedItems,
  disableColumnSelection,
  threeColumn,
}: IProps) => {
  const handleSelectAll = () => {
    let allSelectedItems: SelectedItems = {
      column: [],
      subColOne: [],
      subColTwo: [],
    };

    columns.forEach((column) => {
      const selectedSubColOne = column.subColOne.reduce((acc, subColOne) => {
        if (
          !threeColumn ||
          (subColOne.subColTwo && subColOne.subColTwo.length > 0)
        )
          acc.push(subColOne.id);
        return acc;
      }, [] as string[]);

      const selectedSubColTwo = column.subColOne
        .map((sco) => sco.subColTwo?.map(({ id }) => id) || [])
        .flat();

      allSelectedItems = {
        column:
          column.subColOne.length > 0
            ? [...allSelectedItems.column, column.id]
            : allSelectedItems.column,
        subColOne: [...allSelectedItems.subColOne, ...selectedSubColOne],
        subColTwo: [...allSelectedItems.subColTwo, ...selectedSubColTwo],
      };
    });

    setSelectedItems(allSelectedItems);
  };

  const handleDeSelectAll = () => {
    setSelectedItems({
      column: [],
      subColOne: [],
      subColTwo: [],
    });
  };

  const handleToggleColumnAll = () => {
    if (columns.some((col) => selectedItems.column.includes(col.id))) {
      handleDeSelectAll();
    } else {
      handleSelectAll();
    }
  };

  const isAllSelected = columns.every((col) =>
    selectedItems.column.includes(col.id),
  );
  const isSomeSelected = columns.some((col) =>
    selectedItems.column.includes(col.id),
  );

  return (
    <Checkbox
      sx={{ pl: 0 }}
      disableRipple
      size="small"
      disabled={disableColumnSelection}
      checked={isAllSelected}
      onClick={handleToggleColumnAll}
      indeterminate={!isAllSelected && isSomeSelected}
    />
  );
};

export default ColumnSelectAll;
