import { useSnackbar } from 'notistack';

import {
  ResendAccountInvitationRequest,
  useUsersResendAccountInvitationMutation,
} from 'services/api/general';

const useHandleResendButtonClick = () => {
  const [triggerResendUserInvite, { isLoading }] =
    useUsersResendAccountInvitationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (
    userId: number,
    data: ResendAccountInvitationRequest,
    first_name: string,
  ) =>
    new Promise<string>((resolve, reject) => {
      if (!data.password) {
        reject(new Error('Password is a required field'));
        return;
      }
      triggerResendUserInvite({
        id: userId,
        resendAccountInvitationRequest: data,
      })
        .unwrap()
        .then((resp) => {
          enqueueSnackbar(`Successfully resent invitation to ${first_name}.`, {
            variant: 'success',
          });
          resolve(resp);
        })
        .catch((err) => {
          enqueueSnackbar(
            `Resending invitation to ${first_name} failed: ${JSON.stringify(
              err,
            )}`,
            {
              variant: 'error',
            },
          );
        });
    });

  return {
    handleClick,
    isLoading,
  };
};

export default useHandleResendButtonClick;
