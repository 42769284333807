import React, { useEffect, useRef } from 'react';

import FieldSet from './FieldSet';

interface IProps extends React.HTMLProps<HTMLFormElement> {
  children: React.ReactNode;
  viewOnly?: boolean;
  disableFormSubmit?: boolean;
}

const Form = ({
  children,
  viewOnly,
  disableFormSubmit,
  ...formProps
}: IProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (viewOnly || disableFormSubmit) return undefined;

    const handleFormSubmit = (e: KeyboardEvent) => {
      if (e.key === ('s' || 'S') && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if (formProps.onSubmit && formRef.current) {
          formRef.current.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );
        }
      }
    };

    document.addEventListener('keydown', handleFormSubmit);
    return () => document.removeEventListener('keydown', handleFormSubmit);
  }, [disableFormSubmit]);

  return (
    <form ref={formRef} {...formProps}>
      <FieldSet viewOnly={viewOnly}>{children}</FieldSet>
    </form>
  );
};

export default Form;
