import React, { ReactNode } from 'react';

import { ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';

import WithPermissions from '../Permissions';

type SidebarMenuItemProps = {
  section?: string;
  url: string;
  page: string;
  icon: ReactNode;
  name: string;
  permission: string;
};

const SidebarMenuItem = ({
  section,
  url,
  page,
  icon,
  name,
  permission,
}: SidebarMenuItemProps) => {
  const linkTo = section ? `${section}/${url}` : url;

  return (
    <WithPermissions permission={permission}>
      <Tooltip title={name} placement="right">
        <NavLink to={linkTo}>
          <ListItemButton
            selected={page === url}
            sx={{ padding: 2, justifyContent: 'center' }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>{icon}</ListItemIcon>
          </ListItemButton>
        </NavLink>
      </Tooltip>
    </WithPermissions>
  );
};

export default SidebarMenuItem;
