import type { Column, SelectedItems, SubColOne } from '../../types';

interface ToggleSubColOneArgs {
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
}

const useToggleSubColOne = ({
  selectedItems,
  setSelectedItems,
}: ToggleSubColOneArgs) => {
  const handleSelectSubColOne = (
    subColOne: SubColOne,
    selectedColId: string,
  ) => {
    const sctIds = subColOne.subColTwo?.map((sct) => sct.id) || [];

    setSelectedItems({
      column: !selectedItems.column.includes(selectedColId)
        ? [...selectedItems.column, selectedColId]
        : selectedItems.column,
      subColOne: [...selectedItems.subColOne, subColOne.id],
      subColTwo: [...selectedItems.subColTwo, ...sctIds],
    });
  };

  const handleDeselectSubColOne = (
    subColOne: SubColOne,
    selectedCol: Column,
  ) => {
    const selectedScoIds = selectedItems.subColOne.filter(
      (sco) => sco !== subColOne.id,
    );

    const sctIds = subColOne.subColTwo?.map((sct) => sct.id) || [];

    const selectedSctIds = selectedItems.subColTwo.filter(
      (cid) => !sctIds.includes(cid),
    );

    const scoIds = selectedCol.subColOne.map(({ id }) => id);

    const someSelected = scoIds.some((sco) => selectedScoIds.includes(sco));
    const selectedColId = someSelected
      ? selectedItems.column
      : selectedItems.column.filter((col) => col !== selectedCol.id);

    setSelectedItems({
      ...selectedItems,
      column: selectedColId,
      subColOne: selectedScoIds,
      subColTwo: selectedSctIds,
    });
  };

  const handleToggleSubColOne = (subColOne: SubColOne, selectedCol: Column) => {
    const hasBeenSelected = selectedItems.subColOne.includes(subColOne.id);

    if (hasBeenSelected) handleDeselectSubColOne(subColOne, selectedCol);
    else handleSelectSubColOne(subColOne, selectedCol.id);
  };

  return handleToggleSubColOne;
};

export default useToggleSubColOne;
