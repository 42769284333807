import React, { useState, createContext, useMemo, useContext } from 'react';

interface FrPlatformCtxVals {
  selectedFilter:
    | {
        siteIds: string[];
        zoneIds: string[];
      }
    | undefined;
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<
      | {
          siteIds: string[];
          zoneIds: string[];
        }
      | undefined
    >
  >;
}

const FrPlatformCtx = createContext({} as FrPlatformCtxVals);

interface IProps {
  children: React.ReactNode;
}

const FrPlatformCtxProvider = ({ children }: IProps) => {
  const [selectedFilter, setSelectedFilter] = useState<{
    siteIds: string[];
    zoneIds: string[];
  }>();

  const contextVals = {
    selectedFilter,
    setSelectedFilter,
  };

  const returnVals = useMemo(() => contextVals, [contextVals]);

  return (
    <FrPlatformCtx.Provider value={returnVals}>
      {children}
    </FrPlatformCtx.Provider>
  );
};

export const useFrPlatformCtx = () => useContext(FrPlatformCtx);

export default FrPlatformCtxProvider;
