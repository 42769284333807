import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

type CamerasFilterState = {
  filteredCameraIds: string[];
};

const initialState: CamerasFilterState = {
  filteredCameraIds: [],
};

const camerasFilterSlice = createSlice({
  name: 'camerasFilter',
  initialState,
  reducers: {
    filterCamera: (state, action: PayloadAction<string[]>) => {
      state.filteredCameraIds = action.payload;
    },
  },
});

export const { filterCamera } = camerasFilterSlice.actions;

export default camerasFilterSlice.reducer;
export const getSelectedCameraIds = (state: RootState) =>
  state.camerasFilter.filteredCameraIds;
