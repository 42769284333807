import { useSitesSiteSiteGroupingsQuery } from 'services/api';
import { TabsEnum } from 'services/api/general';

const useConvertCamerasToSubCols = (tab?: TabsEnum) => {
  const { data, isLoading } = useSitesSiteSiteGroupingsQuery({ tab });

  const camerasData = data?.map((site) => ({
    ...site,
    subColOne: site.groups.map((group) => ({
      ...group,
      subColTwo: group.cameras,
    })),
  }));

  return { camerasData, data, isLoading };
};

export default useConvertCamerasToSubCols;
