import React from 'react';

import { Box, Typography, Button, Container } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 8, mb: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh',
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 100, mb: 2 }} color="primary" />
        <Typography variant="h4" component="h1" gutterBottom>
          404 -{t('page.notFound', 'Page Not Found')}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          {t(
            'page.doesNotExist',
            'Sorry, the page you are looking for does not exist.',
          )}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
        >
          {t('page.goToHome', 'Go to Home')}
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
