/* eslint import/no-cycle: [0] */

import { RootState } from 'store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { SelectedItems } from 'components/common/ColumnAndSubColumns/types';

interface FrFilterSlice {
  selectedZones: {
    siteIds: string[];
    zoneIds: string[];
  };
  authorizations: number[];
}

const initialState: FrFilterSlice = {
  selectedZones: {
    siteIds: [],
    zoneIds: [],
  },
  authorizations: [0, 1, 2],
};

const frFilterSlice = createSlice({
  name: 'frZoneFilter',
  initialState,
  reducers: {
    handleSelectZones(
      state,
      { payload: { column, subColOne } }: PayloadAction<SelectedItems>,
    ) {
      state.selectedZones.siteIds = column;
      state.selectedZones.zoneIds = subColOne;
    },

    handleSelectAuthorizations(state, action: PayloadAction<number[]>) {
      state.authorizations = action.payload;
    },
  },
});

export const { handleSelectZones, handleSelectAuthorizations } =
  frFilterSlice.actions;

export default frFilterSlice.reducer;

// Get fr filter states from store
export const useGetFrFilterStates = () => {
  const { selectedZones, authorizations } = useSelector(
    (state: RootState) => state.frFilters,
  );

  return {
    authorizations,
    selectedItems: {
      column: selectedZones.siteIds,
      subColOne: selectedZones.zoneIds,
      subColTwo: [],
    },
  };
};

// Update functions
export const useUpdateFrFilter = () => {
  const dispatch = useDispatch();

  const dispatchSelectAuthorizations = (selectedItems: number[]) =>
    dispatch(handleSelectAuthorizations(selectedItems));

  const dispatchSelectZones = (selectedItems: SelectedItems) =>
    dispatch(handleSelectZones(selectedItems));

  return { dispatchSelectAuthorizations, dispatchSelectZones };
};
