import React from 'react';

import { Box, IconButton, ListItemText } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Item, Submenu, useContextMenu } from 'react-contexify';
import { useNavigate } from 'react-router-dom';

import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import gradientWithComma from 'utils/gradientWithComma';
import CustomContextMenu from 'libs/Contextify/CustomContextMenu';
import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import { Section, Sections } from './sections';
import AuthorisedRoutes from '../../../AuthorisedRoutes';
import useGetCurrentSection from './hooks/useGetSection';
import WithPermissions from '../Permissions';

interface IProps {
  sections: Sections;
}

const MENU_ID = 'congregated-menu-items';

type CongregatedMenuLinkItemProps = Pick<
  Section,
  'path' | 'name' | 'tab_access' | 'permission'
>;

const CongregatedMenuLinkItem = ({
  path,
  name,
  tab_access,
  permission,
}: CongregatedMenuLinkItemProps) => {
  const section = useGetCurrentSection('section');
  const navigate = useNavigate();
  const platformConfig = usePlatformConfigContext();

  return (
    <WithPermissions permission={permission}>
      <AuthorisedRoutes tab_name={tab_access} Appbar>
        <Item
          onClick={() => navigate(path)}
          style={{
            background:
              section === path
                ? platformConfig.navbar_hover_color
                : 'transparent',
          }}
        >
          <ListItemText sx={{ color: 'white' }} primary={name} />
        </Item>
      </AuthorisedRoutes>
    </WithPermissions>
  );
};

const CongregatedSubTabMenuItem = ({ id, name, subTabs }: Section) => {
  const curSection = useGetCurrentSection('section');
  const { data: curUser } = useUsersCurrentRetrieveQuery();
  const platformConfig = usePlatformConfigContext();

  const isSomeTabAccessTrue =
    subTabs &&
    subTabs.some(
      (subTab) => !subTab.tab_access || curUser?.tab_access[subTab.tab_access],
    );

  if (!subTabs || !isSomeTabAccessTrue) return null;

  const isOneChildActive = subTabs.some((subTab) => subTab.path === curSection);

  return (
    <Box
      sx={{
        bgcolor: isOneChildActive
          ? platformConfig.navbar_hover_color
          : 'default',
      }}
    >
      <Submenu
        key={id}
        style={{
          background: gradientWithComma(platformConfig.navbar_color),
        }}
        label={<ListItemText sx={{ color: 'white' }} primary={name} />}
      >
        {/* Sub menu link */}
        {subTabs.map((subMenu) => (
          <CongregatedMenuLinkItem key={subMenu.id} {...subMenu} />
        ))}
      </Submenu>
    </Box>
  );
};

const CongregatedMenuItems = ({ sections }: IProps) => {
  const { data: curUser } = useUsersCurrentRetrieveQuery();

  const platformConfig = usePlatformConfigContext();

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const hasSomeTabAccess = sections.some((section) =>
    section.subTabs
      ? section.subTabs.some(
          (subTab) =>
            !subTab.tab_access || curUser?.tab_access[subTab.tab_access],
        )
      : !section.tab_access || curUser?.tab_access[section.tab_access],
  );

  if (!hasSomeTabAccess) return null;

  return (
    <>
      <IconButton
        onClick={(e) =>
          show({
            event: e,
          })
        }
        color="info"
      >
        <MoreHorizIcon />
      </IconButton>
      <CustomContextMenu
        id={MENU_ID}
        style={{
          background: gradientWithComma(platformConfig.navbar_color),
        }}
      >
        {sections.map((section) =>
          section.subTabs ? (
            <CongregatedSubTabMenuItem key={section.id} {...section} />
          ) : (
            // Single Menu Item
            <CongregatedMenuLinkItem key={section.id} {...section} />
          ),
        )}
      </CustomContextMenu>
    </>
  );
};

export default CongregatedMenuItems;
