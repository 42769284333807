import React, {
  cloneElement,
  forwardRef,
  Ref,
  ReactElement,
  useRef,
  useImperativeHandle,
} from 'react';

import { Popover, PopoverProps } from '@mui/material';
import { useId } from '@mantine/hooks';
import { useOpen } from '../../../hooks';

export interface PopoverInstanceType {
  close: () => void;
  open: () => void;
}

interface IPopoverProps {
  trigger: ReactElement;
  popoverProps?: Omit<PopoverProps, 'open' | 'onClose' | 'anchorEl'>;
  children: React.ReactNode;
}

const CustomPopover = forwardRef(
  (
    { trigger, children, popoverProps }: IPopoverProps,
    ref?: Ref<PopoverInstanceType>,
  ) => {
    const id = useId();

    const [isOpen, open, close] = useOpen();
    const triggerElRef = useRef(null);

    const triggerEl = cloneElement(trigger, {
      onClick: open,
      ref: triggerElRef,
    });

    useImperativeHandle(ref, () => ({ close, open }), []);

    return (
      <>
        <Popover
          id={id}
          open={isOpen}
          onClose={close}
          anchorEl={triggerElRef.current}
          {...popoverProps}
        >
          {children}
        </Popover>
        {triggerEl}
      </>
    );
  },
);

export default CustomPopover;
