import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';

import { type NotificationReadAllTypeName } from 'services/api/general';

const FILTER_BY_TYPES_OPTIONS: {
  value: NotificationReadAllTypeName;
  label: string;
}[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'follow',
    label: 'Follow',
  },
  {
    value: 'mention',
    label: 'Mention',
  },
  {
    value: 'observation',
    label: 'Observation',
  },
];

interface IProps {
  selectedFilterType: NotificationReadAllTypeName;
  setSelectedFilterType: React.Dispatch<
    React.SetStateAction<NotificationReadAllTypeName>
  >;
  disabled?: boolean;
}

const FilterByType = ({
  selectedFilterType,
  setSelectedFilterType,
  disabled,
}: IProps) => {
  const handleChange = (e: SelectChangeEvent) => {
    setSelectedFilterType(e.target.value as NotificationReadAllTypeName);
  };

  return (
    <FormControl sx={{ flex: '1 1 0px' }} size="small">
      <InputLabel id="filter-by-type-label">Type</InputLabel>
      <Select
        labelId="filter-by-type-label"
        id="filter-by-type"
        value={selectedFilterType || ''}
        onChange={handleChange}
        label="Notifications"
        disabled={disabled}
      >
        {FILTER_BY_TYPES_OPTIONS.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterByType;
