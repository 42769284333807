import React, { useMemo } from 'react';

import { Autocomplete, Tooltip, TextField } from '@mui/material';

interface IProps {
  total_pages: number;
  page: number;
  onChange: (page: number) => void;
}

const PaginationCurrentPageInput = ({
  total_pages,
  page,
  onChange,
}: IProps) => {
  const paginationItemsArr = useMemo(
    () => Array.from({ length: total_pages || 0 }, (_, index) => index + 1),
    [total_pages],
  );

  return (
    <Autocomplete
      getOptionLabel={(opt) => opt.toString()}
      renderInput={(p) => (
        <Tooltip title={page}>
          <TextField
            {...p}
            size="small"
            sx={{
              width: '5rem',
            }}
          />
        </Tooltip>
      )}
      value={page}
      options={paginationItemsArr}
      onChange={(_, newVal) => {
        onChange(Number(newVal));
      }}
      disableClearable
    />
  );
};

export default PaginationCurrentPageInput;
