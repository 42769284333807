const VaPermissions = {
  // VIEW
  VIEW_VA_TAB: 'VAV01',
  VIEW_VA_DASHBOARD: 'VARD1',
  VIEW_VA_MANAGEMENT: 'VARM1',
  VIEW_VA_CONFIG: 'VARC1',
  VIEW_VA_LIST_PLATES: 'VARL1',
  VIEW_VA_AUTHORIZATIONS: 'VARL2',
  VIEW_VA_RECORDS: 'VARL3',
  VIEW_VA_CONFIGURATION: 'VARAV',

  // WRITE
  DOWNLOAD_VA_REPORT: 'VARAP',
  TEST_VA_PERIODIC_REPORT: 'VARAT',
  CREATE_VA_LICENSE_PLATE: 'VARAL',
  CREATE_VA_AUTHORIZATION: 'VARAA',
  CREATE_VA_RECORDS: 'VARAR',

  //   UPDATE
  UPDATE_VA_LICENSE_PLATE: 'VARUL',
  UPDATE_VA_AUTHORIZATION: 'VARUA',
  UPDATE_VA_CONFIG: 'VARUC',
  UPDATE_VA_RECORD: 'VARUR',

  //   DELETE
  DELETE_VA_LICENSE_PLATE: 'VARDL',
  DELETE_VA_RECORD: 'VARDR',
  DELETE_VA_AUTHORIZATION: 'VARDU',
};

export default VaPermissions;
