import { SubColOne, SelectedItems, SubColTwo, Column } from '../../types';

interface ToggleSubColTwoArgs {
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
}

const useToggleSubColTwo = ({
  selectedItems,
  setSelectedItems,
}: ToggleSubColTwoArgs) => {
  const handleSelectSubColTwo = (
    subColTwo: SubColTwo,
    selectedCol: string,
    subColOneId: string,
  ) => {
    const { column, subColOne } = selectedItems;

    const allSelectedCol = !column.includes(selectedCol)
      ? [...column, selectedCol]
      : column;

    const allSelectedSubColOne = !subColOne.includes(subColOneId)
      ? [...subColOne, subColOneId]
      : subColOne;

    setSelectedItems({
      ...selectedItems,
      column: allSelectedCol,
      subColOne: allSelectedSubColOne,
      subColTwo: [...selectedItems.subColTwo, subColTwo.id],
    });
  };

  const handleDeselectSubColTwo = (
    subColTwo: SubColTwo,
    selectedColumn: Column,
    subColOne: SubColOne,
  ) => {
    const allSelectedSubColTwo = selectedItems.subColTwo.filter(
      (sct) => sct !== subColTwo.id,
    );

    const someSubColTwoSelected = subColOne.subColTwo?.some((sct) =>
      allSelectedSubColTwo.includes(sct.id),
    );
    const allSelectedSubColOne = someSubColTwoSelected
      ? selectedItems.subColOne
      : selectedItems.subColOne.filter((sco) => sco !== subColOne.id);

    const isSomeSubColOneSelected = allSelectedSubColOne.some((scoId) =>
      selectedItems.subColOne.includes(scoId),
    );

    const allSelectedCol = isSomeSubColOneSelected
      ? selectedItems.column
      : selectedItems.column.filter((col) => col !== selectedColumn.id);

    setSelectedItems({
      column: allSelectedCol,
      subColTwo: allSelectedSubColTwo,
      subColOne: allSelectedSubColOne,
    });
  };

  const handleToggleSubColTwo = (
    subColTwo: SubColTwo,
    subColOne: SubColOne,
    selectedColumn: Column,
  ) => {
    const hasBeenSelected = selectedItems.subColTwo.includes(subColTwo.id);

    if (hasBeenSelected)
      handleDeselectSubColTwo(subColTwo, selectedColumn, subColOne);
    else handleSelectSubColTwo(subColTwo, selectedColumn.id, subColOne.id);
  };

  return handleToggleSubColTwo;
};

export default useToggleSubColTwo;
