import React from 'react';

import { Box, CircularProgress } from '@mui/material';

const LoadingScreen = () => (
  <Box
    sx={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    }}
  >
    <CircularProgress />
  </Box>
);

export default LoadingScreen;
