import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { subWeeks } from 'date-fns';
import { DateRange } from '@mui/x-date-pickers-pro';

import {
  type VehicleManagementAuthorisation,
  type VehicleRecordType,
  VmOperationsFilterType,
} from 'tabs/vehicleManagement/api';

type DurationTime = {
  hour: string;
  minute: string;
};

type DurationType = {
  minimum: DurationTime;
  maximum: DurationTime;
};

export type PrimaryLabelFilter = {
  siteIds: [string] | [];
  labelIds: string[];
};

type VehicleRecordsFilter = {
  selectedRecordType: VehicleRecordType;
  selectedLabels: PrimaryLabelFilter;
  duration: DurationType;
  lpChars: string | undefined;
  vehicleTypes: string[];
  transitTrailCameras: string[];
  entryDateRange: DateRange<Date>;
  exitDateRange: DateRange<Date>;
  entryCameras: string[];
  exitCameras: string[];
  selectedAuthorisations: VehicleManagementAuthorisation[] | undefined;
  selectedOperationsFilter: VmOperationsFilterType[] | undefined;
};

export interface UpdateDurationPayload {
  val: string;
  type: 'minimum' | 'maximum';
  time: 'hour' | 'minute';
}

const initialState: VehicleRecordsFilter = {
  selectedLabels: {
    siteIds: [],
    labelIds: [],
  },
  selectedRecordType: 'transit_trail',
  duration: {
    minimum: {
      hour: '',
      minute: '',
    },
    maximum: {
      hour: '',
      minute: '',
    },
  },
  lpChars: undefined,
  vehicleTypes: [],
  transitTrailCameras: [],
  entryDateRange: [subWeeks(new Date(), 2), new Date()],
  exitDateRange: [null, null],
  entryCameras: [],
  exitCameras: [],
  selectedAuthorisations: undefined,
  selectedOperationsFilter: undefined,
};

const vehicleManagementDashboardFilterSlice = createSlice({
  name: 'vehicleManagementDashboardFilters',
  initialState,
  reducers: {
    updateLabels(state, action: PayloadAction<PrimaryLabelFilter>) {
      state.selectedLabels = action.payload;
    },
    updateRecordType(state, action: PayloadAction<VehicleRecordType>) {
      state.selectedRecordType = action.payload;
    },
    updateDuration(
      { duration },
      { payload: { val, type, time } }: PayloadAction<UpdateDurationPayload>,
    ) {
      duration[type][time] = val;
    },
    resetDuration(state) {
      state.duration = initialState.duration;
    },
    updateLpChars(state, action: PayloadAction<string | undefined>) {
      state.lpChars = action.payload;
    },
    updateVehicleType(state, action: PayloadAction<string[]>) {
      state.vehicleTypes = action.payload;
    },
    updateTransitTrailCameras(state, action: PayloadAction<string[]>) {
      state.transitTrailCameras = action.payload;
    },
    updateVaEntryCameras: (state, action: PayloadAction<string[]>) => {
      state.entryCameras = action.payload;
    },
    updateVaExitCameras: (state, action: PayloadAction<string[]>) => {
      state.exitCameras = action.payload;
    },
    updateVaEntryDateRange: (state, action: PayloadAction<DateRange<Date>>) => {
      state.entryDateRange = action.payload;
    },
    updateVaExitDateRange: (state, action: PayloadAction<DateRange<Date>>) => {
      state.exitDateRange = action.payload;
    },
    updateSelectedAuthorisations: (
      state,
      action: PayloadAction<VehicleManagementAuthorisation[] | undefined>,
    ) => {
      state.selectedAuthorisations = action.payload;
    },
    updateSelectedOperationsFilter: (
      state,
      action: PayloadAction<VmOperationsFilterType[] | undefined>,
    ) => {
      state.selectedOperationsFilter = action.payload;
    },
  },
});

export const {
  updateLabels,
  updateRecordType,
  updateDuration,
  updateLpChars,
  updateVehicleType,
  resetDuration,
  updateTransitTrailCameras,
  updateVaEntryCameras,
  updateVaExitCameras,
  updateVaEntryDateRange,
  updateVaExitDateRange,
  updateSelectedAuthorisations,
  updateSelectedOperationsFilter,
} = vehicleManagementDashboardFilterSlice.actions;

export default vehicleManagementDashboardFilterSlice.reducer;
