import React from 'react';

import { Chip, ChipProps } from '@mui/material';

import { AccountState } from 'services/api/general';

const BADGE_COLORS: Record<AccountState, ChipProps['color']> = {
  invited: 'success',
  active: 'warning',
  inactive: 'error',
};

const AccountStatusBadge = ({ status }: { status: AccountState }) => {
  return (
    status && (
      <Chip
        label={status.toUpperCase()}
        size="small"
        color={BADGE_COLORS[status]}
      />
    )
  );
};

export default AccountStatusBadge;
