export const isEverythingSelected = (
  selectedArr: string[],
  allArr: string[],
) => {
  if (selectedArr.length === 0 || allArr.length === 0) return false;
  return selectedArr.every((selectedId) => allArr.includes(selectedId));
};

export const isSomeSelected = (allArr: string[], selectedArr: string[]) => {
  if (selectedArr.length === 0 || allArr.length === 0) return false;
  return selectedArr.some((selectedId) => allArr.includes(selectedId));
};
