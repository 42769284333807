import React, { PropsWithoutRef, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Grid, Stack, Typography } from '@mui/material';

type ModelHeaderProps = {
  title: string | JSX.Element;
  cancelButtonText?: string;
  handleClose: () => void;
  confirmButton?: ReactElement;
  secondaryActionButton?: ReactElement | null;
};

const ModelHeaderWithSubmit = ({
  title,
  handleClose,
  cancelButtonText,
  confirmButton,
  secondaryActionButton,
}: PropsWithoutRef<ModelHeaderProps>) => {
  const { t } = useTranslation('common');
  return (
    <Grid container sx={{ justifyContent: 'space-between' }}>
      <Grid item>
        <Button onClick={handleClose}>
          {cancelButtonText ?? t('common.cancel', 'Cancel')}
        </Button>
      </Grid>
      <Grid item>
        {typeof title === 'string' ? (
          <Typography variant="h6">{title}</Typography>
        ) : (
          title
        )}
      </Grid>
      <Stack direction="row" spacing={1}>
        <Grid item>{secondaryActionButton}</Grid>
        <Grid item>{confirmButton}</Grid>
      </Stack>
    </Grid>
  );
};

export default ModelHeaderWithSubmit;
