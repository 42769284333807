import React from 'react';

import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import { Box, CssBaseline, Divider, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Appbar from './Appbar';
import Sidebar from './Sidebar';
import SuspenseWithLoading from '../SuspenseWithLoading';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: '45px !important',
  minHeight: 'unset !important',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Header = () => (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Appbar />
    <Drawer variant="permanent">
      <DrawerHeader />
      <Divider />
      <Sidebar />
    </Drawer>
    <Box
      component="main"
      // minWidth : 0 is to prevent the horizontal scroll on va dashboard table and fr dashboard table.
      sx={{ flexGrow: 1, p: 3, paddingBottom: 0, minWidth: '0' }}
    >
      <DrawerHeader />
      <Container maxWidth={false} disableGutters>
        <SuspenseWithLoading>
          <Outlet />
        </SuspenseWithLoading>
      </Container>
    </Box>
  </Box>
);

export default Header;
